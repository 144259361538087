import { LANDING_EDITOR_TABS } from 'adificial-common/dist/enums/adificial'

export default {
  setBaseURL(state, url) {
    state.baseURL = url
  },
  setIsSaving(state, isSaving) {
    state.isSaving = isSaving
  },
  setActiveElement(state, elementObj) {
    if (elementObj?.type === state.activeElement?.type && elementObj?.key === state.activeElement?.key) {
      return
    }

    state.currentEditing = null

    if (!elementObj) {
      state.activeElement = null
      return
    }
    state.activeTab = LANDING_EDITOR_TABS.EDIT
    state.activeElement = {
      element: state.activeLandingPage[elementObj.type][elementObj.key],
      type: elementObj.type,
      key: elementObj.key,
    }
  },
  setActiveTab(state, tab) {
    state.activeTab = tab
  },
  setLandingId(state, id) {
    state.landingId = id
  },
  setLandingName(state, name) {
    state.landingName = name
  },
  setActiveLandingPage(state, landingObj) {
    state.activeLandingPage = landingObj
  },
  setCurrentHover(state, obj) {
    state.currentHover = obj
  },
  setCurrentEditing(state, obj) {
    state.currentEditing = obj
  },
  setDataPoints(state, dataPoints) {
    state.dataPoints = dataPoints
  },
  setConnectionId(state, connectionId) {
    state.connectionId = connectionId
  },
  setHoveredElementString(state, hoveredElementString) {
    state.hoveredElementString = hoveredElementString
  },
  setElementAttribute(state, updateAttributeObject) {
    if (state.activeElement) {
      const { type, key } = state.activeElement
      const { attribute, attributeValue } = updateAttributeObject
      state.activeLandingPage[type][key][attribute] = attributeValue
    } else {
      const { type, key, attribute, attributeValue } = updateAttributeObject
      state.activeLandingPage[type][key][attribute] = attributeValue
    }
  },
  setElementStyles(state, updateAttributeObject) {
    state.activeElement.element.styles[updateAttributeObject.attribute] = updateAttributeObject.attributeValue
    state.activeLandingPage[state.activeElement.type][state.activeElement.key] = state.activeElement.element
  },
  setElementTextStyles(state, updateAttributeObject) {
    state.activeElement.element.textStyles[updateAttributeObject.attribute] = updateAttributeObject.attributeValue
    state.activeLandingPage[state.activeElement.type][state.activeElement.key] = state.activeElement.element
  },
  setAvailableGoogleFonts(state, googleFonts) {
    state.availableFonts.googleFonts = googleFonts
  },
  setLoadedGoogleFonts(_, loadedGoogleFontsItems) {
    loadedGoogleFontsItems.forEach((gfItem) => {
      gfItem.loaded = true
    })
  },
  setGoogleFontsPageInfo(state, { pageSize, page }) {
    state.availableFonts.gFPageSize = pageSize
    state.availableFonts.gFPage = page
  },
  setLandingMetaData(state, metaDataObj) {
    state.activeLandingPage.meta = metaDataObj
  },
  setSelectedConnection(state, connectionId) {
    state.activeLandingPage.connectionId = connectionId
  },
  setDatapointDefaultValues(state, defaultValues) {
    state.activeLandingPage.defaultValues = defaultValues
  },
  setPreviewDefaults(state, usePreviewDefaults) {
    state.usePreviewDefaults = usePreviewDefaults
  },
}
