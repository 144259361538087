import { labelsRE } from 'adificial-common/dist/util/regex'

export default {
  sections(state) {
    return state.sections
  },
  isSaving(state) {
    return state.isSaving
  },
  activeElement(state) {
    return state.activeElement?.element
  },
  landingName(state) {
    return state.landingName
  },
  landingId(state) {
    return state.landingId
  },
  dataPoints(_state, getters) {
    return getters.getSelectedConnection?.dataPoints
  },
  connectionId(state) {
    return state.connectionId
  },
  activeElementType(state) {
    return state.activeElement?.type
  },
  activeElementKey(state) {
    return state.activeElement?.key
  },
  activeTab(state) {
    return state.activeTab
  },
  hoveredElementString(state) {
    return state.hoveredElementString
  },
  activeLandingPage(state) {
    return state.activeLandingPage
  },
  landingMetaData(state) {
    return state.activeLandingPage?.meta
  },
  getDatapointDefaultValues(state) {
    return state.activeLandingPage?.defaultValues || {}
  },
  getPreviewDefaults(state) {
    return state.usePreviewDefaults
  },
  getAllButtonsContent(state) {
    if (!state.activeLandingPage?.buttons) return []

    return Object.values(state.activeLandingPage?.buttons).map((buttonElement) => buttonElement?.label)
  },
  getAllTextsContent(state) {
    if (!state.activeLandingPage?.texts) return []

    return Object.values(state.activeLandingPage?.texts).map((textElement) => textElement.content)
  },
  contentDynamicLabelIds(state, getters) {
    const allContents = [...getters.getAllTextsContent, ...getters.getAllButtonsContent]

    return Array.from(
      allContents.reduce((labelIds, el) => {
        const matches = el.matchAll(labelsRE)
        for (const [, labelId, contents] of matches) {
          labelIds.add(labelId)
        }
        return labelIds
      }, new Set()),
    )
  },
  getSelectedConnection(_state, getters, _, rootGetters) {
    return rootGetters['editor/activeConnections'].find((conn) => conn.id === getters.selectedConnection)
  },
  selectedConnection(state) {
    return state.activeLandingPage?.connectionId
  },
  labelMapping(_state, getters, _, rootGetters) {
    const mapping = getters.getSelectedConnection?.labelMapping?.map(({ labelId, datapoint }) => {
      return {
        labelId,
        datapoint,
        label: rootGetters?.['editor/userCompany']?.labels?.find((companyLabel) => companyLabel.id === labelId)?.label,
      }
    })
    return mapping
  },
  currentHover(state) {
    return state.currentHover
  },
  currentEditing(state) {
    return state.currentEditing
  },
  getElementByTypeAndKey:
    (state) =>
    ({ type, key }) => {
      return state.activeLandingPage[type][key]
    },
  availableFonts(state) {
    return state.availableFonts
  },

  availableGoogleFonts(_, getters) {
    return getters.availableFonts?.googleFonts
  },

  loadedGoogleFonts(_, getters) {
    return getters.availableGoogleFonts?.filter((gfItem) => gfItem.loaded)
  },

  availableGoogleFontsItems(_, getters) {
    return getters.availableGoogleFonts?.items
  },

  loadedGoogleFontsItems(_, getters) {
    return getters.availableGoogleFonts?.items?.filter((gfItem) => gfItem.loaded)
  },

  currentGFPage(_, getters) {
    return getters.availableFonts.gFPage
  },

  currentGFPageSize(_, getters) {
    return getters.availableFonts.gFPageSize
  },
}
