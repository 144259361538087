import defaultSection from 'adificial-common/dist/defaults/defaultSection'
import defaultLandingPage from 'adificial-common/dist/defaults/defaultLandingPage'
import { DEFAULT_LANDING_NAME } from 'adificial-common/dist/enums/landingEditor'

export default () => ({
  sections: [defaultSection(), defaultSection()],
  activeLandingPage: defaultLandingPage(),
  activeElement: null,
  landingName: DEFAULT_LANDING_NAME,
  currentHover: {
    type: null,
    key: null,
  },
  currentEditing: {
    type: null,
    key: null,
  },
  activeTab: '',
  landingId: null,
  dataPoints: [],
  usePreviewDefaults: false,
  connectionId: undefined,
  availableFonts: {
    googleFonts: undefined,
    gFPageSize: undefined,
    gFPage: undefined,
    customFonts: undefined,
  },
  isSaving: false,
})
