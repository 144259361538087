import { Action, createStore } from 'vuex'
import globalActions from '@/store/global/actions'
import globalMutations from '@/store/global/mutations'
import globalGetters from '@/store/global/getters'
import globalState from '@/store/global/state'
/* Content Player */
import adActions from '~~/store/adstudio/actions'
import adGetters from '@/store/adstudio/getters'
import adMutations from '@/store/adstudio/mutations'
import adState from '@/store/adstudio/state'
/* Landing Page Editor */
import LPEActions from '~~/store/landingEditor/actions'
import LPEGetters from '@/store/landingEditor/getters'
import LPEMutations from '@/store/landingEditor/mutations'
import LPEState from '@/store/landingEditor/state'
/* Adstudio */
import editorActions from '~~/store/editor/actions'
import editorGetters from '@/store/editor/getters'
import editorMutations from '@/store/editor/mutations'
import editorState from '@/store/editor/state'
import { createUndoRedoPlugin } from '~~/plugins/UndoRedo/UndoRedo'
import { actionsToTrack, actionsToTrackAndHide } from '~~/store/editor/userActions'
const ACTION_HISTORY_SIZE = 20

const localSavePlugin = (store: any) => {
  store.watch(
    (_: any, getters: any) => JSON.stringify(getters['editor/content']),
    (newSerializedContent: string, oldSerializedContent: string) => {
      const isPristineContent = newSerializedContent === oldSerializedContent
      store.commit('editor/setShouldAutosave', !isPristineContent)
      if (!isPristineContent && window?.location?.href?.includes('/adstudio')) {
        const contentLocalSave = JSON.parse(newSerializedContent)
        if (contentLocalSave.scenes)
          contentLocalSave.scenes.forEach((scene: any) => {
            if (scene.elements) scene.elements.forEach((el: any) => (el.playing = false))
            scene.playing = false
          })
        contentLocalSave.update_at = new Date().getTime()
        contentLocalSave.contentType = store.getters['editor/contentType']
        localStorage.setItem('contentLocalSave', JSON.stringify(contentLocalSave))
      }
    },
    { deep: true },
  )
}

const formatedActionsToTrack = Object.keys(actionsToTrack).map((userActionName) => {
  return {
    type: `editor/${userActionName}`,
    hide: false,
    ...(userActionName === 'setContentName' && {
      onUndo: { type: 'editor/saveContent', payload: { generateThumbnail: false } },
      onRedo: { type: 'editor/saveContent', payload: { generateThumbnail: false } },
    }),
    ...(userActionName === 'setElementMedia' && {
      serialize: (action: { type: string; payload: { file: File } }) => ({
        actionSnapshot: JSON.stringify(action),
        file: action.payload.file,
      }),
      deserialize: (serializedAction: { actionSnapshot: string; file: File }) => {
        const action = JSON.parse(serializedAction.actionSnapshot)
        return {
          ...action,
          payload: { ...action.payload, file: serializedAction.file },
        }
      },
    }),
  }
})

const formatedActionsToTrackAndHide = Object.keys(actionsToTrackAndHide).map((userActionName) => ({
  type: `editor/${userActionName}`,
  hide: true,
}))

export default defineNuxtPlugin((nuxtApp) => {
  // @ts-ignore for providing access to the auth module in the editor actions
  const auth = nuxtApp.$auth
  const store = createStore({
    modules: {
      global: {
        namespaced: true,
        actions: globalActions,
        mutations: globalMutations,
        getters: globalGetters,
        state: { globalState },
      },
      adstudio: {
        namespaced: true,
        actions: adActions,
        getters: adGetters,
        mutations: adMutations,
        state: adState,
      },
      editor: {
        namespaced: true,
        actions: editorActions({ auth }),
        getters: editorGetters,
        mutations: editorMutations,
        state: editorState,
      },
      landingEditor: {
        namespaced: true,
        actions: LPEActions({ auth }),
        getters: LPEGetters,
        mutations: LPEMutations,
        state: LPEState,
      },
    },
    plugins: [
      createUndoRedoPlugin({
        parentModuleName: 'editor',
        resetStateActionName: 'editor/resetContent',
        // actionsToTrack: [...formatedActionsToTrack, ...formatedActionsToTrackAndHide],
        actionsToTrack: [...formatedActionsToTrack],
        historySize: ACTION_HISTORY_SIZE,
        updateInitialStateActionName: 'editor/updateInitialContent',
        stateGetter: 'editor/content',
        debug: false,
      }),
      // Not sure why the order here matters. Shouldn't be the case but this plugin should come after the UndoRedo plugin to detect changes properly
      // Found this open issue in the vuex library: https://github.com/vuejs/vuex/issues/2197
      localSavePlugin,
      (store) => {
        // @ts-ignore
        store.$auth = auth
      },
    ],
  })

  nuxtApp.vueApp.use(store)
  store.commit('global/setBaseURL', nuxtApp.$config.public.API_BASE_URL)
  store.commit('adstudio/setBaseURL', nuxtApp.$config.public.API_BASE_URL)
  store.commit('editor/setBaseURL', nuxtApp.$config.public.API_BASE_URL)
  store.commit('landingEditor/setBaseURL', nuxtApp.$config.public.API_BASE_URL)
  return {
    provide: {
      store,
    },
  }
})
