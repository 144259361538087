import {
  CONTENT_TYPE,
  ELEMENT_BACKGROUND_TYPE,
  ELEMENT_TYPE,
  TEXT_ELEMENT_STYLE,
} from 'adificial-common/dist/enums/adstudio'
import { CONNECTION_TYPE } from 'adificial-common/dist/enums/connections'
import { datapointsRE, labelsRE } from 'adificial-common/dist/util/regex'

function canUndoOrRedo(getters) {
  return !getters.isPreviewingAnimations && !getters.isSaving && !getters.isPlayingContent
}

export default {
  chapter(state) {
    return state.chapter
  },
  scenes(state) {
    return state.chapter?.scenes || []
  },
  getSceneIndex(_state, getters) {
    return (scene) => getters.scenes.indexOf(scene)
  },
  activeElements(state, getters) {
    try {
      if (getters.contentType === CONTENT_TYPE.CHAPTER) {
        return getters.scenes.filter((scene) => scene.active)[0].elements.filter((element) => element.active)
      } else if (getters.contentType === CONTENT_TYPE.SCENE_LAYOUT) {
        return state.sceneLayout.elements.filter((element) => element.active)
      }
    } catch (error) {
      return []
    }
  },
  currentlyPreviewingElements(_, getters) {
    return getters.activeScene?.elements?.filter((el) => el.previewing)
  },
  isPreviewingAnimations(_, getters) {
    return !!getters.currentlyPreviewingElements?.length
  },
  contentType(state) {
    return state.contentType
  },
  activeScene(state, getters) {
    return state.contentType === CONTENT_TYPE.CHAPTER ? getters.scenes.find((scene) => scene.active) : state.sceneLayout
  },
  activeSceneIndex(state, getters) {
    return getters.scenes.findIndex((scene) => scene.active)
  },
  transform(state) {
    return state.chapter?.transform
  },
  isTextEditing(state) {
    return state.isTextEditing
  },
  isEditingOptions(state) {
    return state.isEditingOptions
  },
  subMenu(state) {
    return state.subMenu
  },
  backgrounds(_, getters) {
    const backgrounds = []

    getters.scenes.forEach((scene) => {
      scene.elements.forEach((el) => {
        if (el.type === ELEMENT_TYPE.BACKGROUND) {
          backgrounds.push(el)
        }
      })
    })

    return backgrounds
  },
  activeBackground(_, getters) {
    const activeBackground = getters.activeScene
      ? getters.activeScene.elements.find((el) => el.type === ELEMENT_TYPE.BACKGROUND)
      : undefined
    return activeBackground
  },
  media(state) {
    return state.media
  },
  isEditing(state) {
    return state.isEditing
  },
  colorHistory(state) {
    return state.chapter.colorHistory
  },
  activeConnections(state) {
    return state.connections.filter((conn) => conn.isActive)
  },
  activeWithLabelsValidConnections(state, getters) {
    return getters.activeConnections.filter((conn) => {
      if (conn.connectionType === CONNECTION_TYPE.CSV_FILE) {
        return (
          conn.labelMapping &&
          Array.isArray(conn.labelMapping) &&
          conn.isValidated &&
          conn.validationResult?.result === 'valid'
        )
      } else if (conn.connectionType === CONNECTION_TYPE.SFTP_FOLDER) {
        return conn.labelMapping
      } else {
        return false
      }
    })
  },
  userProfile(state) {
    return state.userProfile
  },
  userCompany(_state, getters) {
    return getters.userProfile?.company
  },
  token(_state, _getters, _rootState, rootGetters) {
    return rootGetters['global/auth'].accessToken
  },
  sceneLayout(state) {
    return state.sceneLayout
  },
  contentFonts(_, getters) {
    const fonts = getters.scenes.reduce((accFonts, currentScene) => {
      const sceneFontFamilies = currentScene.elements
        .filter((el) => el.type === ELEMENT_TYPE.TEXT)
        .map((el) => el.textStyles[TEXT_ELEMENT_STYLE.FONT_FAMILY])
      sceneFontFamilies.forEach((fontFamily) => accFonts.add(fontFamily))
      return accFonts
    }, new Set())

    return Array.from(fonts)
  },
  fontPage(state) {
    return state.fontPage
  },

  fontPageSize(state) {
    return state.fontPageSize
  },
  selectedConnection(state) {
    return state.chapter.connectionId
  },
  getSelectedConnection(_state, getters) {
    return getters.activeConnections.find((conn) => conn.id === getters.selectedConnection)
  },
  datapoints(_state, getters) {
    return getters.getSelectedConnection?.dataPoints
  },
  datapointsToManuallyUpgrade(_state, getters) {
    return Array.from(
      getters.allElements
        ?.filter((el) => el.type === ELEMENT_TYPE.TEXT)
        ?.reduce((datapoints, el) => {
          const matches = el.html.matchAll(datapointsRE)
          for (const [, dp1, dp2] of matches) {
            const datapoint = dp1 ?? dp2
            if (!getters.landingMapping?.find((lm) => lm.datapoint === datapoint)) datapoints.add(datapoint)
          }
          return datapoints
        }, new Set()),
    )
  },
  labelMapping(_state, getters) {
    return getters.getSelectedConnection?.labelMapping?.map(({ labelId, datapoint }) => {
      return {
        labelId,
        datapoint,
        label: getters.userCompany?.labels?.find((companyLabel) => companyLabel.id === labelId)?.label,
      }
    })
  },
  contentDynamicLabelIds(state, getters) {
    return Array.from(
      getters.allElements
        .filter((el) => el.type === ELEMENT_TYPE.TEXT)
        .reduce((labelIds, el) => {
          const matches = el.html.matchAll(labelsRE)
          for (const [, labelId, contents] of matches) {
            labelIds.add(labelId)
          }
          return labelIds
        }, new Set()),
    )
  },
  getSceneTransitionsConfig(state) {
    return state.sceneTransitionsConfig
  },
  sceneTransitions(state) {
    return state.chapter.sceneTransitions
  },
  isNew(state, getters) {
    const isNewChapter = state.chapter ? !state.chapter.chapterId : true
    return getters.contentType === CONTENT_TYPE.CHAPTER ? isNewChapter : !state.sceneLayout.id
  },
  shouldRefresh(state) {
    return state.shouldRefresh
  },
  shouldRedirect(state) {
    return state.shouldRedirect
  },
  allElements(state, getters) {
    let result
    if (getters.contentType === CONTENT_TYPE.CHAPTER) {
      result = state.chapter?.scenes?.reduce((prevSceneElements, currentScene) => {
        return prevSceneElements.concat(currentScene.elements)
      }, [])
    } else if (getters.contentType === CONTENT_TYPE.SCENE_LAYOUT) {
      result = getters.sceneLayout.elements
    }
    return result || []
  },
  contentName(state, getters) {
    return getters.contentType === CONTENT_TYPE.CHAPTER ? state.chapter.chapterName : state.sceneLayout.sceneName
  },
  isSaving(state) {
    return state.isSaving
  },
  content(state, getters) {
    return getters.contentType === CONTENT_TYPE.CHAPTER ? state.chapter : state.sceneLayout
  },
  shouldAutosave(state) {
    return state.shouldAutosave
  },
  brands(state) {
    return state.brands
  },
  currentBrand(state) {
    return state.currentBrand
  },
  currentBrandIndex(state, getters) {
    return state.brands.findIndex((brand) => brand.id === getters.currentBrand?.id)
  },
  defaultBrandIndex(state) {
    return state.brands.findIndex((brand) => brand.isDefault === 1)
  },
  showProfileMenu(state) {
    return state.showProfileMenu
  },
  isResizing(state) {
    return state.isResizing
  },
  savedSceneId: (state) => (idx) => state.chapter.scenes[idx]?.sceneId,
  accountAccess(state) {
    return state.accountAccess
  },
  elementsCopied(state) {
    return state.elementsCopied
  },
  getElementById(state, getters) {
    return (elementId) => getters.allElements.find((el) => el.id === elementId)
  },
  parsedInitialContent(state) {
    return JSON.parse(state.initialContent)
  },
  canUndo(_state, getters) {
    return getters['actions/canUndoAction'] && canUndoOrRedo(getters)
  },
  canRedo(_state, getters) {
    return getters['actions/canRedoAction'] && canUndoOrRedo(getters)
  },
  getElementSceneIndex(_state, getters) {
    return (elementId) =>
      getters.scenes.findIndex((scene) => scene.elements.find((sceneElement) => sceneElement.id === elementId))
  },
  getLastBGValues(state) {
    return {
      [ELEMENT_BACKGROUND_TYPE.IMAGE]: state.lastBGImage,
      [ELEMENT_BACKGROUND_TYPE.COLOR]: state.lastBGColor,
      [ELEMENT_BACKGROUND_TYPE.VIDEO]: state.lastBGVideo,
    }
  },
  zoom(state) {
    return state.zoom
  },
  availableFeaturesForUser(state) {
    return state.userProfile?.permissions
  },
  isFeatureAvailableForUser: (_state, getters) => (feature) => {
    return getters.availableFeaturesForUser?.includes(feature)
  },
  getDatapointDefaultValues(state) {
    return state.chapter?.defaultValues || {}
  },
  getPreviewDefaults(state) {
    return state.usePreviewDefaults
  },
  getSelectedElementDynamicImage(_state, getters) {
    return getters.activeElements?.[0]?.dynamicImage
  },
  getActiveBackgroundDynamicImage(_state, getters) {
    return getters.activeBackground?.dynamicImage
  },
  isPlayingContent(_state, _getters, _rootState, rootGetters) {
    return rootGetters['adstudio/isPlayingContent']
  },
  sceneVoiceOver(_state, getters) {
    return getters.activeScene?.voiceOver
  },
  sceneVoiceOverByIndex: (_state, getters) => (sceneIndex) => {
    return getters.scenes?.[sceneIndex]?.voiceOver
  },
  voiceOverState(state) {
    return state.voiceOverState
  },
  voiceOverConfig(state) {
    return state.chapter.voiceOverConfig
  },
  chapterAudioState(state) {
    return state.chapterAudioState
  },
  dynamicImages(state) {
    return state.dynamicImages
  },
  editingDynamicImageIndex(state) {
    return state.editingDynamicImageIndex
  },
  getFirstTimerProperty: (state) => (property) => {
    if (!Object.hasOwn(state.firstTimer, property)) {
      return
    }
    return state.firstTimer[property]
  },
  mutateSceneContent(state) {
    return state.mutateSceneContent
  },
  audioRequestID(state) {
    return state.audioRequestID
  },
  audioRequest(state) {
    return state.audioRequest
  },
  needsToRegenerateAudio(state) {
    return state.needsToRegenerateAudio
  },
  showUnsavedChangesModal(state) {
    return state.showUnsavedChangesModal
  },
  unsavedVoiceOverChanges(state) {
    return state.unsavedVoiceOverChanges
  },
}
