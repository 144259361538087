import { USER_MEDIA_TYPES } from 'adificial-common/dist/enums/adstudio'
import {
  saveLanding,
  uploadPendingImagesFiles,
  fetchUpdateLangingPages,
  fetchCreateLangingPages,
  fetchLandingPage,
  fetchUploadFavicon,
  searchMediaByName,
} from './api'

export default function useLandingPageEditorActions({ auth }) {
  const exports = {
    async fetchLandingPage(store, landingId) {
      await auth.refreshTokenIfRequired()
      return fetchLandingPage(store, landingId)
    },
    async fetchCreateLangingPages(store, body) {
      await auth.refreshTokenIfRequired()
      return fetchCreateLangingPages(store, body)
    },
    async fetchUpdateLangingPages(store, body) {
      await auth.refreshTokenIfRequired()
      return fetchUpdateLangingPages(store, body)
    },
    async fetchUploadFavicon(store, body) {
      await auth.refreshTokenIfRequired()
      return fetchUploadFavicon(store, body)
    },
    async uploadPendingImagesFiles(store, body) {
      await auth.refreshTokenIfRequired()
      return uploadPendingImagesFiles(store, body)
    },
    async saveLanding(store, landingId) {
      await auth.refreshTokenIfRequired()
      return saveLanding(store, landingId)
    },
    async searchMediaByName(store, landingId) {
      await auth.refreshTokenIfRequired()
      return searchMediaByName(store, landingId)
    },
    async uploadMedia(store, { file }) {
      await auth.refreshTokenIfRequired()
      store.commit('global/setIsFetching', true, { root: true })
      const fileReader = new FileReader()
      const mb = 1e6
      const maxAllowedSize = 50 * mb

      if (file.size > maxAllowedSize) {
        const message = 'Could not import media: File exceeds maximum size limit (50MB)'
        store.dispatch('global/showToast', { message, type: 'error' }, { root: true })
        store.commit('global/setIsFetching', false, { root: true })
        return
      }

      fileReader.addEventListener('load', (evt) => {
        const result = fileReader.result
        const image = new Image()
        // For calculating width and heigth
        image.onload = () => {
          const updateAttributeObj = {
            attribute: 'file',
            attributeValue: {
              naturalWidth: image.naturalWidth,
              naturalHeight: image.naturalHeight,
              size: Math.round(this.fileSize),
            },
          }
          store.commit('setElementAttribute', updateAttributeObj)
        }

        image.src = result

        store.commit('setElementAttribute', {
          attributeValue: result,
          attribute: 'src',
        })
      })

      fileReader.readAsDataURL(file)

      file.mediaName = file.name
      file.mediaType = USER_MEDIA_TYPES.IMAGE

      store.commit('setElementAttribute', {
        attribute: 'pendingFile',
        attributeValue: file,
      })
      store.commit('setElementAttribute', {
        attribute: 'name',
        attributeValue: file.name,
      })

      const existingFileResponse = await searchMediaByName(store, file.name)

      if (existingFileResponse) {
        store.commit('setElementAttribute', {
          attributeValue: existingFileResponse?.mediaUrl,
          attribute: 'src',
        })
        store.commit('setElementAttribute', {
          attribute: 'pendingFile',
          attributeValue: undefined,
        })

        store.commit('global/setIsFetching', false, { root: true })
      } else {
        store
          .dispatch(
            'editor/fetchSaveMedia',
            {
              mediaFile: file,
            },
            { root: true },
          )
          .then((response) => {
            if (response.mediaUrl) {
              store.commit('setElementAttribute', {
                attributeValue: response.mediaUrl,
                attribute: 'src',
              })
              store.commit('setElementAttribute', {
                attribute: 'pendingFile',
                attributeValue: undefined,
              })
            } else {
              console.log(response)
            }
          })
          .catch((e) => {
            console.error(e)
          })
          .finally(() => {
            store.commit('global/setIsFetching', false, { root: true })
          })
      }
    },
  }

  return exports
}
