import { useToast } from 'vue-toastification'
import { defaultChapter, defaultSceneLayout, defaultAudio } from 'adificial-common/dist/defaults'
import {
  MEDIA_TYPES,
  FILE_TYPE,
  FILE_TYPE_NEW_FORMAT,
  USER_MEDIA_TYPES_STR,
  ANIMATION_MOTION_SMOOTHING_TYPES,
  ANIMATION_INTENSITY_TYPES,
} from 'adificial-common/dist/enums/adstudio'
import { CONNECTION_TYPE } from 'adificial-common/dist/enums/connections'
import defaultBrandProfile from 'adificial-common/dist/defaults/defaultBrandProfile'

const toast = useToast()
const DEFAULT_POST_OPTIONS = {
  method: 'POST',
  mode: 'cors',
  headers: {
    Authorization: 'null',
    'Content-Type': 'application/json',
  },
}

const defaultFetchOptions = ({ method, token, mode }) => ({
  method: method || 'POST',
  mode: mode || 'cors',
  headers: {
    Authorization: token || 'null',
    'Content-Type': 'application/json',
  },
})

export const IMAGES_PER_PAGE = 30

export const fetchChapter = ({ commit, state }, body) => {
  const options = {
    ...DEFAULT_POST_OPTIONS,
    body: JSON.stringify(body),
  }

  return fetch(`${state.baseURL}/chapters/get`, options)
    .then((response) => response.json())
    .catch(() => {
      toast.error('API Error')
    })
    .then(({ data, statusCode }) => {
      if (statusCode !== 201) {
        toast.error('Chapter not found')
      } else {
        let chapter
        const contentLocalSave =
          localStorage.getItem('contentLocalSave') && JSON.parse(localStorage.getItem('contentLocalSave'))

        if (
          contentLocalSave &&
          contentLocalSave.chapterId === data.id &&
          contentLocalSave.update_at >= data.update_at &&
          window?.location?.href?.includes('/adstudio')
        ) {
          // If there is a local copy and the local copy is more recent than the one available online
          chapter = contentLocalSave
        } else {
          chapter = data
        }

        chapter.chapterId = chapter.id ?? chapter.chapterId
        delete chapter.id

        chapter?.scenes?.forEach((scene) => {
          scene.timeoutId = undefined
        })

        // This adds some properties to the chapter that are not being stored in the backend. E.g transform property
        // so there are no missing properties when loading a chapter
        const sanitizedChapter = {
          ...defaultChapter(),
          ...chapter,
        }

        updateContentStructureInPlace(sanitizedChapter)

        commit('setChapter', sanitizedChapter)
        commit('setActiveScene', 0)
        commit('setInitialContent', sanitizedChapter)
      }
    })
}

export const fetchMedia = async ({ commit, getters, state }, { mediaType }, $infiniteState) => {
  const companyId = getters.userProfile.companyId
  const limit = IMAGES_PER_PAGE
  const media = getters.media
  const offset = media[MEDIA_TYPES.CURRENT_STOCK_PAGE] * IMAGES_PER_PAGE
  const query = media[MEDIA_TYPES.QUERY]

  let mediaArray

  const contentType = FILE_TYPE_NEW_FORMAT[mediaType]

  if (!query) {
    const payloadObj = {
      limit,
      offset,
      conditions: {
        companyId,
        contentType,
      },
    }

    const options = {
      ...DEFAULT_POST_OPTIONS,
      headers: {
        Authorization: getters.token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payloadObj),
    }

    const response = await fetch(`${state.baseURL}/media/list`, options)
    const json = await response.json()

    mediaArray = json.data?.map((item) => ({
      mediaId: item.id,
      mediaUrl: item.pathAddress,
      mediaName: item.mediaName,
      thumbnailURL: item.videoThumbnailUrl || undefined,
      metadata: item?.metadata,
    }))
  } else {
    mediaArray = await searchMedia({ state, commit, getters }, { query, mediaType, companyId, limit, offset })
  }

  if (mediaArray.length < limit) {
    $infiniteState?.complete()
  } else {
    $infiniteState?.loaded()
  }

  if (mediaArray.length)
    commit('setMedia', {
      type: MEDIA_TYPES.CURRENT_STOCK_PAGE,
      value: media[MEDIA_TYPES.CURRENT_STOCK_PAGE] + 1,
    })

  let type = mediaType === FILE_TYPE.IMAGE ? MEDIA_TYPES.STOCK_IMAGES : MEDIA_TYPES.STOCK_VIDEOS

  switch (mediaType) {
    case FILE_TYPE.IMAGE:
      type = MEDIA_TYPES.STOCK_IMAGES
      break
    case FILE_TYPE.VIDEO:
      type = MEDIA_TYPES.STOCK_VIDEOS
      break
    case FILE_TYPE.AUDIO:
      type = MEDIA_TYPES.STOCK_AUDIO
      break
    default:
      break
  }

  commit('setMedia', {
    type,
    value: [...media[type], ...mediaArray],
  })
}

export const fetchRoyaltyFreeAudio = async ({ commit, getters, state }, { mediaType }, $infiniteState) => {
  const companyId = getters.userProfile.companyId
  const limit = IMAGES_PER_PAGE
  const offset = state.media[MEDIA_TYPES.CURRENT_PAGE] * IMAGES_PER_PAGE
  const query = state.media[MEDIA_TYPES.QUERY]
  try {
    const contentType = FILE_TYPE_NEW_FORMAT[mediaType]
    const payloadObj = query
      ? {
          adificial: { limit, offset: 0, contentType },
          searchText: query || '',
        }
      : {
          limit,
          offset,
          mediaType: contentType,
          sortField: 'uploadDate',
          sortOrder: 'DESC',
          query: query || '',
        }

    const options = {
      ...defaultFetchOptions({ token: getters.token }),
      body: JSON.stringify(payloadObj),
    }

    const endpoint = query ? 'search' : 'list'

    const response = await fetch(`${state.baseURL}/stock/${endpoint}`, options)
    const json = await response.json()

    let mediaArray

    if (query) {
      const providerObj = json?.stocksMediaSearch?.filter(
        (arrayOfproviders) => arrayOfproviders?.provider === 'adificial',
      )?.[0]
      const stockAudios = providerObj?.data

      mediaArray = stockAudios?.map((item) => {
        return {
          mediaId: item.id,
          mediaUrl: item.pathAddress,
          mediaName: item.mediaName,
          metadata: item?.metadata,
        }
      })
    } else {
      mediaArray = json.medias.response?.map((item) => {
        return {
          mediaId: item.id,
          mediaUrl: item.url,
          mediaName: item.name,
          metadata: item?.metadata,
        }
      })
    }

    commit('setMedia', {
      type: MEDIA_TYPES.CURRENT_PAGE,
      value: state.media[MEDIA_TYPES.CURRENT_PAGE] + 1,
    })

    if (mediaArray.length < limit) {
      $infiniteState?.complete()
    } else {
      $infiniteState?.loaded()
    }

    const type = MEDIA_TYPES.THIRD_PARTY_AUDIO
    commit('setMedia', {
      type,
      value: state.media[MEDIA_TYPES.CURRENT_PAGE] === 1 ? mediaArray : [...state.media[type], ...mediaArray],
    })
  } catch (error) {
    $infiniteState?.error()
    return error
  }
}

export const fetchPexelsImages = async ({ commit, state }, $infiniteState) => {
  try {
    const page = state.media[MEDIA_TYPES.CURRENT_PAGE]
    const query = state.media[MEDIA_TYPES.QUERY]

    const response = await fetch(
      `https://api.pexels.com/v1/${query ? 'search' : 'curated'}?per_page=${IMAGES_PER_PAGE}&page=${page}&query=${
        query || ''
      }`,
      {
        headers: { Authorization: '563492ad6f91700001000001cebce4f2f7c7443b9590ac1c646ea231' },
      },
    )

    const data = await response.json()

    const mediaList = data?.photos

    const imagesArray = mediaList.map((item) => ({
      mediaId: item.id,
      mediaUrl: item.src.large,
      mediaName: 'IMG_' + item.id,
      mediaWidth: item.width,
      mediaHieght: item.height,
    }))

    if (mediaList.length < IMAGES_PER_PAGE) {
      $infiniteState?.complete()
    } else {
      // $infiniteState.loaded()
    }

    commit('setMedia', {
      type: MEDIA_TYPES.CURRENT_PAGE,
      value: state.media[MEDIA_TYPES.CURRENT_PAGE] + 1,
    })

    commit('setMedia', {
      type: MEDIA_TYPES.PEXELS,
      value:
        state.media[MEDIA_TYPES.CURRENT_PAGE] === 1
          ? imagesArray
          : [...state.media[MEDIA_TYPES.PEXELS], ...imagesArray],
    })
  } catch (error) {
    return error
  }
}

export const fetchPexelsVideos = ({ commit, state }, $infiniteState) => {
  try {
    const page = state.media[MEDIA_TYPES.CURRENT_PAGE]
    const query = state.media[MEDIA_TYPES.QUERY]

    const response = fetch(
      `https://api.pexels.com/videos/${query ? 'search' : 'popular'}?per_page=${30}&page=${page}&query=${query || ''}`,
      {
        headers: { Authorization: '563492ad6f91700001000001cebce4f2f7c7443b9590ac1c646ea231' },
      },
    )
      .then((response) => response.json())
      .then((data) => {
        const mediaList = data?.videos
        const imagesArray = mediaList.map((item) => ({
          mediaId: item.id,
          videoThumbnail: item.image,
          mediaUrl: item.video_files[0].link,
          mediaName: 'VIDEO_' + item.id,
          mediaWidth: item.width,
          mediaHieght: item.height,
          metadata: {
            duration: item.duration,
          },
        }))

        if (mediaList.length < IMAGES_PER_PAGE) {
          $infiniteState?.complete()
        } else {
          // $infiniteState.loaded()
        }

        commit('setMedia', {
          type: MEDIA_TYPES.CURRENT_PAGE,
          value: state.media[MEDIA_TYPES.CURRENT_PAGE] + 1,
        })

        commit('setMedia', {
          type: MEDIA_TYPES.PEXELS_VIDEOS,
          value:
            state.media[MEDIA_TYPES.CURRENT_PAGE] === 1
              ? imagesArray
              : [...state.media[MEDIA_TYPES.PEXELS_VIDEOS], ...imagesArray],
        })
      })
  } catch (error) {
    $infiniteState.error()
    return error
  }
}

export const fetchConnections = ({ commit, state, getters }, companyId) => {
  const options = {
    ...DEFAULT_POST_OPTIONS,
    headers: {
      Authorization: getters.token,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ companyId }),
  }

  fetch(`${state.baseURL}/connection/list`, options)
    .then((response) => response.json())
    .catch(() => {
      commit('setAPIError', true)
      toast.error('API Error')
    })
    .then(async (response) => {
      const connectionList = response.data

      const filteredConnections = connectionList.filter((item) => {
        if (item.connectionType === CONNECTION_TYPE.CSV_FILE || item.connectionType === CONNECTION_TYPE.SFTP_FOLDER) {
          return true
        }
        return false
      })

      await commit('setConnections', filteredConnections || [])
    })
}

export const fetchGenerateTextToSpeech = ({ commit, state, getters }, { payload, sceneIndex, audioType }) => {
  const options = {
    ...DEFAULT_POST_OPTIONS,
    headers: {
      Authorization: getters.token,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  }

  commit('setVoiceOverState', {
    isLoading: true,
    errorMessage: '',
  })

  function getAudioMetadata(mediaUrl) {
    return new Promise((resolve) => {
      const audio = new Audio()
      function callback(e) {
        const duration = e.target.duration
        const name = e.target.name
        resolve({ duration, name })
      }
      audio.addEventListener('loadedmetadata', callback, false)
      audio.src = mediaUrl
    })
  }

  fetch(`${state.baseURL}/ai-services/textToSpeech`, options)
    .then((response) => response.json())
    .catch(() => {
      commit('setAPIError', true)
      toast.error('API Error')
    })
    .then(async (response) => {
      if (response?.status === 'SUCCESS') {
        const audioObj = getters.sceneVoiceOverByIndex(sceneIndex) || defaultAudio()
        const metadata = await getAudioMetadata(response?.url)
        audioObj.name = response?.name || response?.url?.split('/')[response?.url?.split('/').length - 1] || ''
        audioObj.src = response?.url
        audioObj.duration = metadata?.duration
        audioObj.textToSpeech = payload.text
        audioObj.audioType = audioType
        audioObj.textToSpeechConfig = {
          voiceName: payload?.voiceName,
        }

        commit('setSceneVoiceOver', {
          voiceOver: audioObj,
          index: sceneIndex,
        })
        commit('setUnsavedVoiceOverChanges', null)
        toast.success('Audio generated successfully')
      } else if (response?.status === 'ERROR') {
        toast.error(response?.description)
      }
    })
    .finally(() => {
      commit('setVoiceOverState', {
        isLoading: false,
        errorMessage: '',
      })
    })
}

export const fetchSceneLayout = ({ commit, state }, { id }) => {
  const options = {
    ...DEFAULT_POST_OPTIONS,
    body: JSON.stringify({ id }),
  }

  fetch(`${state.baseURL}/chapters/getSceneLayout`, options)
    .then((response) => {
      return response.json()
    })
    .then((response) => {
      const sceneLayout = response.data

      const sanitizedSceneLayout = {
        ...defaultSceneLayout(),
        ...sceneLayout.sceneObject,
        id: sceneLayout.id,
        sceneName: sceneLayout.sceneName,
      }

      updateContentStructureInPlace(sanitizedSceneLayout)

      commit('setSceneLayout', sanitizedSceneLayout)
      if (state.isEditing) {
        commit('setActiveScene', 0)
      }
      return sanitizedSceneLayout
    })
    .catch((e) => {
      console.log(e)
      commit('setAPIError', true)
      commit('setSceneLayout', defaultSceneLayout())
      toast.error('API Error')
    })
}

export const saveMedia = async ({ state, commit, getters }, { mediaFile, getErrorMessage }) => {
  const companyId = getters.userProfile.companyId
  const mb = 1e6
  const maxAllowedSize = 50 * mb
  let errorMessage

  if (mediaFile.size > maxAllowedSize) {
    errorMessage = 'File exceeds maximum size limit (50MB)'
    toast.error(errorMessage)
  } else {
    const payload = {
      mediaName: mediaFile.name,
      contentType: USER_MEDIA_TYPES_STR[mediaFile.mediaType],
      tags: [],
      ...(mediaFile.brandId && { brandId: mediaFile.brandId }),
      companyId,
    }

    const options = {
      ...DEFAULT_POST_OPTIONS,
      headers: {
        Authorization: getters.token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    }

    const handleAPIError = () => {
      commit('setAPIError', true)
      toast.error('API Error')
      commit('global/setIsFetching', false, { root: 'true' })
    }

    const uploadURLResponse = await fetch(`${state.baseURL}/media/getUploadUrl`, options)
    const response = await uploadURLResponse.json()
    const { message, statusCode } = response
    const hasError = statusCode !== 201
    errorMessage = message
    if (!hasError) {
      const { uploadUrl, contentType, mediaId, mediaUrl } = response

      const deleteFile = (id) => {
        fetch(`${state.baseURL}/media/delete/${id}`, {
          method: 'DELETE',
          headers: {
            Authorization: getters.token,
            'Content-Type': 'application/json',
          },
        })
          .then((response) => response.json())
          .catch(handleAPIError)
          .then((response) => {
            console.log(response)
          })
      }

      const uploadOptions = {
        method: 'PUT',
        mode: 'cors',
        headers: {
          'Content-Type': contentType,
        },
        body: mediaFile,
      }

      try {
        const uploadResponse = await fetch(uploadUrl, uploadOptions)

        if (uploadResponse.ok) {
          toast.success('New media imported successfully', { timeout: 500 })
          return { mediaId, mediaUrl, mediaName: mediaFile.name }
        } else {
          // Delete the file is the upload went wrong to allow the user to try again
          deleteFile(mediaId)
        }
      } catch (e) {
        errorMessage = 'API Error'
        commit('setAPIError', true)
        toast.error(errorMessage)
        deleteFile(mediaId)
        commit('global/setIsFetching', false, { root: 'true' })
      }
    } else if (message.includes('Media') && message.includes('brandId') && message.includes('combination')) {
      errorMessage = 'A file with the same name already exists under your Brand Identity Media vault'
      toast.error('A file with the same name already exists under your Brand Identity Media vault')
      commit('global/setIsFetching', false, { root: 'true' })
    } else if (errorMessage) {
      toast.error(errorMessage)
    }
  }

  if (getErrorMessage) {
    return { message: errorMessage || undefined }
  }
}

export const deleteMediaFile = ({ state, commit, getters }, { id }) => {
  const handleAPIError = () => {
    commit('setAPIError', true)
    toast.error('API Error')
    commit('global/setIsFetching', false, { root: 'true' })
  }

  fetch(`${state.baseURL}/media/delete/${id}`, {
    method: 'DELETE',
    headers: {
      Authorization: getters.token,
      'Content-Type': 'application/json',
    },
  })
    .then((response) => response.json())
    .catch(handleAPIError)
    .then((response) => {
      toast.success(response?.message, { timeout: 900 })
    })
}

export const searchMedia = async ({ state, commit, getters }, { query, mediaType, companyId, limit, offset }) => {
  const url = encodeURI(`${state.baseURL}/media/search`)

  const contentType = mediaType ? FILE_TYPE_NEW_FORMAT[mediaType] : undefined

  const payload = {
    ...(contentType && { contentType }),
    ...(limit !== undefined && limit),
    ...(offset !== undefined && offset),
    conditions: { companyId },
    searchText: query,
  }

  const options = { ...defaultFetchOptions({ token: getters?.token }), body: JSON.stringify(payload) }

  return await fetch(url, options)
    .then((response) => {
      return response.json()
    })
    .catch(() => {
      commit('setAPIError', true)
      toast.error('API Error')
    })
    .then((parsedResponse) =>
      (parsedResponse?.data || [])
        .map((mediaMatch) => mediaMatch.mediaObject)
        .map((item) => ({
          mediaId: item.id,
          mediaUrl: item.pathAddress,
          mediaName: item.mediaName,
          thumbnailURL: item.videoThumbnailUrl || undefined,
        })),
    )
}

export const searchMediaByName = async ({ state, commit, getters }, { query, mediaType }) => {
  const companyId = getters.userProfile.companyId
  const mediaResults = await searchMedia({ state, commit, getters }, { query, companyId, mediaType })
  const result = mediaResults.find((media) => media.mediaName === query)
  return result
}

const deactivateAllComponents = (content) => {
  // To handle both chapters and scene layouts
  const scenes = content.scenes ? content.scenes : [content]
  scenes.forEach((scene) => {
    scene.active = scene.playing = false
    scene.elements.forEach((element) => {
      element.active = element.previewing = element.playing = false
    })
  })
}

const prepareContentForSaving = (content) => {
  const contentCopy = JSON.parse(JSON.stringify(content))
  deactivateAllComponents(contentCopy)
  return contentCopy
}

const updateAnimationsFormat = (content) => {
  // To handle both chapters and scene layouts
  const scenes = content.scenes ? content.scenes : [content]
  scenes.forEach((scene) => {
    scene.elements.forEach((element) => {
      if (element.animation !== undefined) {
        element.animationsTimeline = [element.animation]
        delete element.animation
      }

      if (element.animationsTimeline !== undefined) {
        element.animationsTimeline.forEach((animation) => {
          if (
            animation.timingFunction === 'linear' &&
            animation.motionSmoothing === undefined &&
            animation.intensity === undefined
          ) {
            animation.motionSmoothing = ANIMATION_MOTION_SMOOTHING_TYPES.IN_OUT
            animation.intensity = ANIMATION_INTENSITY_TYPES.MEDIUM
            delete animation.timingFunction
          }
        })
      }
    })
  })
}

const updateContentStructureInPlace = (content) => {
  updateAnimationsFormat(content)
  return content
}

const sendAudioRequest = async (chapterId, state, getters, commit) => {
  await fetch(`${state.baseURL}/audio-services/submitAudioRequest`, {
    method: 'POST',
    mode: 'cors',
    headers: {
      Authorization: getters.token,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ chapterId }),
  }).then(async (response) => {
    if (response.ok) {
      const { requestId } = await response.json()
      commit('setAudioRequestID', requestId)
    } else {
      throw new Error('There was an error when generating the chapter audio.')
    }
  })
}

export const createChapter = async ({ state, getters, commit }, content) => {
  const options = {
    ...DEFAULT_POST_OPTIONS,
    headers: {
      Authorization: getters.token,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(prepareContentForSaving(content)),
  }

  return await fetch(`${state.baseURL}/chapters/create`, options)
    .catch(() => {
      return { hasError: true, status: 401, message: 'API ERROR 2' }
    })
    .then((response) => {
      const hasError = !response.ok
      if (hasError) {
        return { hasError, status: response.status, message: 'API Error' }
      } else {
        return response.json()
      }
    })
    .then(async (response) => {
      const { chapterId } = response
      try {
        await sendAudioRequest(chapterId, state, getters, commit)
        return response
      } catch (e) {
        return { hasError: true, status: 500, message: e.message }
      }
    })
}

export const getTextToSpeechVoices = async ({ state, commit, getters }, content) => {
  const options = {
    ...defaultFetchOptions({ method: 'GET', token: getters.token }),
    headers: {
      Authorization: getters.token,
      'Content-Type': 'application/json',
    },
  }

  return await fetch(`${state.baseURL}/ai-services/listVoices`, options)
    .then((response) => {
      const hasError = !response.ok
      if (hasError) {
        return { hasError, status: response.status, message: 'API Error' }
      } else {
        return response.json()
      }
    })
    .then((data) => {
      commit('setMedia', {
        type: MEDIA_TYPES.AVAILABLE_AI_VOICES,
        value: data?.voices || [],
      })
    })
    .catch(() => {
      return { hasError: true, status: 401, message: 'API ERROR 2' }
    })
}

export const updateChapter = async ({ state, commit, getters }, content) => {
  const options = {
    method: 'PUT',
    mode: 'cors',
    headers: {
      Authorization: getters.token,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(prepareContentForSaving(content)),
  }

  return await fetch(`${state.baseURL}/chapters/update/${content.chapterId}`, options)
    .then(async (response) => {
      const hasError = !response.ok
      if (hasError) {
        const payload = await response.json()
        const message = payload?.message || 'API ERROR'
        return { hasError, status: response.status, message }
      } else {
        return response.json()
      }
    })
    .catch(() => {
      return { hasError: true, status: 401, message: 'API ERROR 2' }
    })
    .then(async (response) => {
      const chapterId = content.chapterId
      try {
        await sendAudioRequest(chapterId, state, getters, commit)
        return response
      } catch (e) {
        return { hasError: true, status: 500, message: e.message }
      }
    })
}

export const createSceneLayout = async ({ state, getters }, content) => {
  const sceneObject = prepareContentForSaving(content)
  delete sceneObject.sceneName
  delete sceneObject.id
  delete sceneObject.generateThumbnail

  const options = {
    ...DEFAULT_POST_OPTIONS,
    headers: {
      Authorization: getters.token,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ scene: sceneObject, type: 0, name: content.sceneName }),
  }

  return await fetch(`${state.baseURL}/chapters/saveIndividualScene`, options)
    .then((response) => {
      const hasError = !response.ok
      if (hasError) {
        return { hasError, status: response.status, message: 'API Error' }
      } else {
        return response.json()
      }
    })
    .catch(() => {
      return { hasError: true, status: 401, message: 'API ERROR 2' }
    })
}

export const updateSceneLayout = async ({ state, getters }, content) => {
  const sceneObject = prepareContentForSaving(content)
  const id = '' + content.id
  const name = '' + content.sceneName

  delete sceneObject.sceneName
  delete sceneObject.id
  delete sceneObject.generateThumbnail

  const payload = { scene: sceneObject, sceneIndex: 0, name }

  const options = {
    method: 'PUT',
    mode: 'cors',
    headers: {
      Authorization: getters.token,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  }
  return await fetch(`${state.baseURL}/chapters/updateIndividualScene/${id}`, options)
    .then((response) => {
      const hasError = !response.ok
      if (hasError) {
        return { hasError, status: response.status, message: 'API Error' }
      } else {
        return response.json()
      }
    })
    .catch(() => {
      return { hasError: true, status: 401, message: 'API ERROR 2' }
    })
}

export const fetchBrands = async ({ state, commit, getters }, companyId) => {
  const options = {
    ...DEFAULT_POST_OPTIONS,
    headers: {
      Authorization: getters.token,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ conditions: { companyId } }),
  }

  await fetch(`${state.baseURL}/brand/list`, options)
    .then((response) => response.json())
    .catch(() => {
      commit('setAPIError', true)
      toast.error('API Error')
    })
    .then((response) => {
      const brands = response.data
      brands.forEach((brand) => {
        if (!brand.brandStyles) {
          brand.brandStyles = defaultBrandProfile()
        }
      })
      commit('setBrands', brands)
    })
}

export const fetchProfile = async ({ state, commit, getters }) => {
  const requestOptions = {
    headers: {
      Authorization: getters.token,
      'Content-Type': 'application/json',
    },
  }

  const options = {
    ...DEFAULT_POST_OPTIONS,
    ...requestOptions,
  }

  const userResponse = await fetch(`${state.baseURL}/user/userInfo`, options)
  const userJson = await userResponse.json()
  const user = userJson.data
  commit('setUserProfile', user)

  const permissionsUrl = encodeURI(`${state.baseURL}/user/${user.id}/permissions`)
  const permissionsResponse = await fetch(permissionsUrl, requestOptions)
  const permissionsJson = await permissionsResponse.json()
  const permissions = permissionsJson.permissions || []

  commit(
    'setAvailableFeaturesForUser',
    permissions.map((permission) => permission.permission_name),
  )
}

export const fetchSetSceneToFavorites = async ({ state, getters }, payload) => {
  const options = {
    ...DEFAULT_POST_OPTIONS,
    headers: {
      Authorization: getters.token,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  }

  return await fetch(`${state.baseURL}/chapters/saveIndividualScene`, options)
    .then((response) => {
      return response.json()
    })
    .catch(() => {
      commit('setAPIError', true)
      toast.error('API Error')
    })
    .then((response) => {
      return response
    })
}

export const fetchRemoveSceneFromFavorites = async ({ state, commit, getters }, id) => {
  const options = {
    ...defaultFetchOptions({ method: 'DELETE', token: getters.token }),
  }

  return await fetch(`${state.baseURL}/chapters/deleteScene/${id}`, options)
    .then((response) => {
      return response.json()
    })
    .catch(() => {
      commit('setAPIError', true)
      toast.error('API Error')
    })
    .then((response) => {
      return response
    })
}

export const fetchFavoritesScenes = async ({ state, commit, getters }, { companyId, isTemplate }) => {
  const payloadObj = {
    limit: 999,
    offset: 0,
    conditions: {
      companyId,
    },
  }
  const options = {
    ...defaultFetchOptions({ method: 'POST', token: getters.token }),
    body: JSON.stringify(payloadObj),
  }

  return await fetch(`${state.baseURL}/chapters/listIndividualScenes`, options)
    .then((response) => {
      return response.json()
    })
    .catch(() => {
      commit('setAPIError', true)
      toast.error('API Error')
    })
    .then((response) => {
      if (response?.data?.length) {
        return response?.data?.map((item) => ({
          ...item,
          // Updates Scene object
          sceneObject: updateContentStructureInPlace(item?.sceneObject),
        }))
      }
      return []
    })
}

export const fetchCompanyLabels = ({ state, commit, getters }, { companyId }) => {
  const options = {
    ...defaultFetchOptions({ method: 'GET', token: getters.token }),
  }

  return fetch(`${state.baseURL}/companies/labels/${companyId}`, options)
    .then((response) => response.json())
    .then((response) => {
      return { labels: response.labels }
    })
    .catch((error) => {
      console.error(error)
      commit('setAPIError', true)
      toast.error(`API Error: ${error}`)
      return { hasError: error, message: error }
    })
}

export const fetchAudioRequest = ({ state, commit, getters }, id) => {
  const options = {
    ...defaultFetchOptions({ method: 'GET', token: getters.token }),
  }

  return fetch(`${state.baseURL}/audio-services/request/${id}`, options)
    .then((response) => response.json())
    .catch((error) => {
      console.error(error)
      commit('setAPIError', true)
      toast.error(`API Error: ${error}`)
      return { hasError: error, message: error }
    })
}
