import { LANDING_PAGES_IDS, DEFAULT_LANDING_NAME } from 'adificial-common/dist/enums/landingEditor'
import { USER_MEDIA_TYPES, USER_MEDIA_TYPES_STR } from 'adificial-common/dist/enums/adstudio'
import defaultLandingPage from 'adificial-common/dist/defaults/defaultLandingPage'
import { useMergeJson } from '@/composables/useMergeJson'

const { mergeMissingKeys } = useMergeJson()

const defaultFetchOptions = ({ method, token, contentType }) => ({
  method: method || 'POST',
  mode: 'cors',
  headers: {
    Authorization: token || 'null',
    'Content-Type': contentType || 'application/json',
  },
})

export const fetchLandingPage = ({ commit, state, rootGetters, dispatch }, landingId) => {
  const payloadObj = {
    id: landingId,
  }

  const options = { ...defaultFetchOptions({ token: rootGetters['editor/token'] }), body: JSON.stringify(payloadObj) }

  fetch(`${state.baseURL}/landing-page/get`, options)
    .then((response) => response.json())
    .catch(() => {
      dispatch(
        'global/showToast',
        {
          message: 'API Error',
          type: 'error',
        },
        { root: true },
      )
    })
    .then((response) => {
      commit('setLandingId', response?.data?.id)
      const hasError = response?.statusCode > 300
      const { data } = response

      if (response?.data) {
        const mergedLandingObject = data?.jsonObject
          ? mergeMissingKeys(data.jsonObject, defaultLandingPage())
          : undefined
        commit('setLandingName', data.landingPageName || '')
        commit('setActiveLandingPage', mergedLandingObject || defaultLandingPage())
      }

      if (response.message) {
        dispatch(
          'global/showToast',
          {
            message: response.message,
            type: hasError ? 'error' : 'success',
          },
          { root: true },
        )
      }
    })
}

export const saveLanding = async ({ dispatch, commit }, landingId) => {
  commit('global/setIsFetching', true, { root: true })
  commit('setIsSaving', true)

  if (landingId) {
    await dispatch('fetchUpdateLangingPages', landingId)
  } else {
    await dispatch('fetchCreateLangingPages')
  }

  commit('setIsSaving', false)
  commit('global/setIsFetching', false, { root: true })
}

export const searchMedia = async ({ state, dispatch, rootGetters }, { query, mediaType, companyId, limit, offset }) => {
  const url = encodeURI(`${state.baseURL}/media/search`)

  const contentType = mediaType ? FILE_TYPE_NEW_FORMAT[mediaType] : undefined

  const conditions = {
    ...(companyId !== undefined && { companyId }),
    ...(contentType !== undefined && { contentType }),
  }
  const payload = {
    ...(limit !== undefined && limit),
    ...(offset !== undefined && offset),
    ...(Object.keys(conditions).length !== 0 && { conditions }),
    searchText: query,
    contentType: USER_MEDIA_TYPES_STR[USER_MEDIA_TYPES.IMAGE],
  }

  const options = { ...defaultFetchOptions({ token: rootGetters['editor/token'] }), body: JSON.stringify(payload) }

  return await fetch(url, options)
    .then((response) => {
      return response.json()
    })
    .catch(() => {
      dispatch(
        'global/showToast',
        {
          message: 'API Error',
          type: 'error',
        },
        { root: true },
      )
    })
    .then((parsedResponse) =>
      (parsedResponse?.data || [])
        .map((mediaMatch) => mediaMatch.mediaObject)
        .map((item) => ({
          mediaId: item.id,
          mediaUrl: item.pathAddress,
          mediaName: item.mediaName,
          thumbnailURL: item.videoThumbnailUrl || undefined,
        })),
    )
}

export const searchMediaByName = async ({ state, commit, rootGetters }, mediaName) => {
  const mediaResults = await searchMedia({ state, commit, rootGetters }, { query: mediaName })
  const result = mediaResults.find((media) => media.mediaName === mediaName)
  return result
}

export const uploadPendingImagesFiles = async ({ state, dispatch, commit, rootGetters }, body) => {
  if (!state.activeLandingPage?.images) return

  const promises = []

  for (const key of Object.keys(state.activeLandingPage.images)) {
    const image = state.activeLandingPage.images[key]

    if (image?.pendingFile) {
      const myFile = new File([image.pendingFile], `${Date.now()}${image?.pendingFile?.mediaName}`)
      myFile.mediaType = image?.pendingFile?.mediaType
      const promise = dispatch(
        'editor/fetchSaveMedia',
        {
          mediaFile: myFile,
        },
        { root: true },
      )
      promises.push(promise)
    }
  }

  const results = await Promise.all(promises)

  for (let i = 0; i < results.length; i++) {
    const data = results[i]
    const key = Object.keys(state.activeLandingPage.images)[i]

    commit('setElementAttribute', {
      type: 'images',
      key,
      attribute: 'pendingFile',
      attributeValue: undefined,
    })

    if (data?.mediaUrl) {
      commit('setElementAttribute', {
        type: 'images',
        key,
        attribute: 'src',
        attributeValue: data?.mediaUrl || '',
      })
    }
  }

  return Promise.resolve()
}

export const fetchUploadFavicon = ({ state, rootGetters, getters, dispatch, commit }, { favicon }) => {
  commit('global/setIsFetching', true, { root: true })

  if (!getters?.landingId) {
    dispatch(
      'global/showToast',
      {
        message: 'Please save your create/save your landing before uploading a favicon',
        type: 'error',
      },
      { root: true },
    )

    commit('setLandingMetaData', {
      ...getters.landingMetaData,
      favicon: undefined,
    })
  }

  const payloadObj = new FormData()
  payloadObj.append('landingPageId', getters.landingId)
  payloadObj.append('faviconFile', favicon)

  const options = {
    mode: 'cors',
    headers: {
      Accept: '*/*',
      Authorization: rootGetters['editor/token'],
    },
    method: 'POST',
    body: payloadObj,
  }

  fetch(`${state.baseURL}/landing-page/uploadFavicon`, options)
    .then((response) => response.json())
    .catch(() => {
      dispatch(
        'global/showToast',
        {
          message: 'API Error',
          type: 'error',
        },
        { root: true },
      )
      commit('setLandingMetaData', {
        ...getters.landingMetaData,
        favicon: undefined,
      })
    })
    .then((response) => {
      const hasError = response?.statusCode > 300

      if (!hasError && !response?.error?.message) {
        if (response.files?.length) {
          commit('setElementAttribute', {
            type: 'meta',
            key: 'favicon',
            attribute: 'src',
            attributeValue: response.files?.[0]?.url,
          })

          commit('setLandingMetaData', {
            ...getters.landingMetaData,
            favicon: {
              ...getters.landingMetaData.favicon,
              files: response?.files,
            },
          })

          dispatch(
            'global/showToast',
            {
              message: 'Favicon uploaded successfully',
              type: 'success',
            },
            { root: true },
          )
        }

        commit('setElementAttribute', {
          type: 'meta',
          key: 'favicon',
          attribute: 'pendingFile',
          attributeValue: undefined,
        })
      } else {
        dispatch(
          'global/showToast',
          {
            message: response?.error?.message || 'API Error',
            type: 'error',
          },
          { root: true },
        )
        commit('setLandingMetaData', {
          ...getters.landingMetaData,
          favicon: undefined,
        })
      }

      if (response?.message) {
        dispatch(
          'global/showToast',
          {
            message: response.message,
            type: hasError ? 'error' : 'success',
          },
          { root: true },
        )
      }
    })
    .finally(() => {
      commit('global/setIsFetching', false, { root: true })
    })
}

export const fetchUpdateLangingPages = ({ state, rootGetters, dispatch, commit }, landingId) => {
  const userProfile = rootGetters['editor/userProfile']

  const payloadObj = {
    companyId: userProfile?.companyId,
    templateId: LANDING_PAGES_IDS.STANDARD_PAGE,
    landingPageName: state.landingName,
    htmlString: '',
    jsonObject: state.activeLandingPage,
  }

  const options = {
    ...defaultFetchOptions({ token: rootGetters['editor/token'], method: 'PUT' }),
    body: JSON.stringify(payloadObj),
  }

  fetch(`${state.baseURL}/landing-page/update/${landingId}`, options)
    .then((response) => response.json())
    .catch(() => {
      dispatch(
        'global/showToast',
        {
          message: 'API Error',
          type: 'error',
        },
        { root: true },
      )
    })
    .then((response) => {
      const hasError = response?.statusCode > 300

      if (response?.message) {
        dispatch(
          'global/showToast',
          {
            message: response.message,
            type: hasError ? 'error' : 'success',
          },
          { root: true },
        )
      }
    })
}

export const fetchCreateLangingPages = ({ state, rootGetters, commit, dispatch }) => {
  const userProfile = rootGetters['editor/userProfile']

  const payloadObj = {
    companyId: userProfile?.companyId,
    templateId: LANDING_PAGES_IDS.STANDARD_PAGE,
    landingPageName: state.landingName,
    htmlString: '',
    jsonObject: state.activeLandingPage,
  }

  if (state.landingName === DEFAULT_LANDING_NAME) {
    dispatch(
      'global/showToast',
      {
        message: 'Remember to name your Landing Page and save it to keep your work and enable the autosave feature!',
        type: 'success',
      },
      { root: true },
    )

    return
  }

  const options = { ...defaultFetchOptions({ token: rootGetters['editor/token'] }), body: JSON.stringify(payloadObj) }

  fetch(`${state.baseURL}/landing-page/create`, options)
    .then((response) => response.json())
    .catch(() => {
      dispatch(
        'global/showToast',
        {
          message: 'API Error',
          type: 'error',
        },
        { root: true },
      )
    })
    .then((response) => {
      commit('setLandingId', response?.landingPageId)
      const hasError = response?.statusCode > 300

      if (response?.message) {
        dispatch(
          'global/showToast',
          {
            message: response.message,
            type: hasError ? 'error' : 'success',
          },
          { root: true },
        )
      }
    })
}
