import {
  BIconZoomIn,
  BIconZoomOut,
  BIconFullscreen,
  BIconAspectRatio,
  BIconArrowClockwise,
  BIconArrowCounterclockwise,
  BIconCaretDownFill,
  BIconCaretUpFill,
  BIconTrash,
  BIconPlusCircle,
  BIconList,
  BIconImage,
  BIconFilm,
  BIconFileFontFill,
  BIconBoundingBoxCircles,
  BIconLockFill,
  BIconUnlockFill,
  BIconEyeFill,
  BIconEyeSlashFill,
  BIconTypeBold,
  BIconTypeItalic,
  BIconTypeUnderline,
  BIconFileImage,
  BIconX,
  BIconTextLeft,
  BIconTextCenter,
  BIconTextParagraph,
  BIconTextRight,
  BIconFonts,
  BIconSearch,
  BIconStarFill,
  BIconStar,
  BIconCheck2,
  BIconMicFill,
  BIconGrid3x3GapFill,
  BIconMusicNoteBeamed,
  BIconSoundwave,
  BIconPlayCircleFill,
  BIconPauseCircleFill,
} from 'bootstrap-icons-vue'

export default defineNuxtPlugin((NuxtApp) => {
  NuxtApp.vueApp.component('BIconZoomIn', BIconZoomIn)
  NuxtApp.vueApp.component('BIconZoomOut', BIconZoomOut)
  NuxtApp.vueApp.component('BIconFullscreen', BIconFullscreen)
  NuxtApp.vueApp.component('BIconAspectRatio', BIconAspectRatio)
  NuxtApp.vueApp.component('BIconArrowClockwise', BIconArrowClockwise)
  NuxtApp.vueApp.component('BIconArrowCounterclockwise', BIconArrowCounterclockwise)
  NuxtApp.vueApp.component('BIconCaretDownFill', BIconCaretDownFill)
  NuxtApp.vueApp.component('BIconCaretUpFill', BIconCaretUpFill)
  NuxtApp.vueApp.component('BIconPlusCircle', BIconPlusCircle)
  NuxtApp.vueApp.component('BIconTrash', BIconTrash)
  NuxtApp.vueApp.component('BIconList', BIconList)
  NuxtApp.vueApp.component('BIconImage', BIconImage)
  NuxtApp.vueApp.component('BIconFilm', BIconFilm)
  NuxtApp.vueApp.component('BIconFileFontFill', BIconFileFontFill)
  NuxtApp.vueApp.component('BIconBoundingBoxCircles', BIconBoundingBoxCircles)
  NuxtApp.vueApp.component('BIconLockFill', BIconLockFill)
  NuxtApp.vueApp.component('BIconUnlockFill', BIconUnlockFill)
  NuxtApp.vueApp.component('BIconEyeFill', BIconEyeFill)
  NuxtApp.vueApp.component('BIconEyeSlashFill', BIconEyeSlashFill)
  NuxtApp.vueApp.component('BIconTypeBold', BIconTypeBold)
  NuxtApp.vueApp.component('BIconTypeItalic', BIconTypeItalic)
  NuxtApp.vueApp.component('BIconTypeUnderline', BIconTypeUnderline)
  NuxtApp.vueApp.component('BIconFileImage', BIconFileImage)
  NuxtApp.vueApp.component('BIconX', BIconX)
  NuxtApp.vueApp.component('BIconTextLeft', BIconTextLeft)
  NuxtApp.vueApp.component('BIconTextCenter', BIconTextCenter)
  NuxtApp.vueApp.component('BIconTextParagraph', BIconTextParagraph)
  NuxtApp.vueApp.component('BIconTextRight', BIconTextRight)
  NuxtApp.vueApp.component('BIconFonts', BIconFonts)
  NuxtApp.vueApp.component('BIconSearch', BIconSearch)
  NuxtApp.vueApp.component('BIconStarFill', BIconStarFill)
  NuxtApp.vueApp.component('BIconStar', BIconStar)
  NuxtApp.vueApp.component('BIconCheck2', BIconCheck2)
  NuxtApp.vueApp.component('BIconMicFill', BIconMicFill)
  NuxtApp.vueApp.component('BIconGrid3x3GapFill', BIconGrid3x3GapFill)
  NuxtApp.vueApp.component('BIconMusicNoteBeamed', BIconMusicNoteBeamed)
  NuxtApp.vueApp.component('BIconSoundwave', BIconSoundwave)
  NuxtApp.vueApp.component('BIconPlayCircleFill', BIconPlayCircleFill)
  NuxtApp.vueApp.component('BIconPauseCircleFill', BIconPauseCircleFill)
})
