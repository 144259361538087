import { Auth0VueClientOptions, createAuth0 } from '@auth0/auth0-vue'
import { DateTime } from 'luxon'
import { useToast } from 'vue-toastification'
import { Store } from 'vuex'
const debug = false
const toast = useToast()

export default defineNuxtPlugin((nuxtApp) => {
  if (debug) console.log('Registering auth plugin...')

  const config = useRuntimeConfig()
  const route = useRoute()

  const options: Auth0VueClientOptions = {
    domain: 'auth.adificial.net',
    clientId: config.public.AUTH0_CLIENT_ID,
    useRefreshTokens: true,
    useRefreshTokensFallback: true,
    authorizationParams: {
      redirect_uri: `${window.location.origin}/login`,
      audience: config.public.AUTH0_AUDIENCE,
      scope: 'openid profile email offline_access',
    },
  }

  const isLoggedIn = ref(false)

  const auth0 = createAuth0(options)
  if (process.client) nuxtApp.vueApp.use(auth0)

  async function setupAuth() {
    const store = nuxtApp.$store as Store<any>
    if (store) {
      store.commit('global/setIsFetching', true)
      const { access_token: accessToken, expires_in: expiresIn } = await auth0.getAccessTokenSilently({
        detailedResponse: true,
      })
      if (debug) console.log('refreshed token', { accessToken, expiresIn })
      store.dispatch('global/setToken', { accessToken, expiresIn })
      await store.dispatch('editor/getUserProfile')
      await store.dispatch('editor/fetchCompanyLabels')
      store.commit('global/setIsFetching', false)
      isLoggedIn.value = true
    }
  }

  async function refreshTokenIfRequired() {
    const store = nuxtApp.$store as Store<any>
    try {
      const { expiration } = store.getters['global/auth']
      const now = DateTime.now()
      if (now > expiration) {
        if (debug) console.log('Refreshing tokens', { now, expiration })
        await setupAuth()
      }
    } catch (error) {
      if (debug) console.log('Got an error refreshing the token', { error })
      store.commit('editor/setShouldRefresh', true)

      if (route.path.toLowerCase().includes('thumbnail') || route.path.toLowerCase().includes('preview/scenelayout')) {
        return
      }

      toast.error('Refreshing your session in order to save your changes. Please wait...', {
        onClose: () => window.location.reload(),
        timeout: 2000,
      })
    }
  }

  addRouteMiddleware(
    'auth',
    async (to, from) => {
      if (debug) console.log('Registering auth route middleware')
      if (process.client) {
        const authorizationParams = {
          audience: config.public.AUTH0_AUDIENCE,
        }
        if (debug) console.log('Auth route middleware', document.cookie)
        // If no auth0.{clientId}.is.authenticated cookie is present, this will report as no session available
        await auth0.checkSession({ detailedResponse: true, authorizationParams })
        if (!auth0.isAuthenticated.value) {
          try {
            const result = await auth0.getAccessTokenSilently({
              detailedResponse: true,
            })
            if (debug) console.log('Manual auth result', result)
          } catch (error) {
            console.log('Got an error trying manual auth refresh', error)
            auth0.loginWithRedirect({
              appState: {
                target: to.fullPath,
              },
            })
          }
        }
      }
    },
    { global: true },
  )

  watch(auth0.isAuthenticated, async (isAuthenticated, wasAuthenticated): Promise<any> => {
    if (debug) console.log('isAuthenticated changed', { isAuthenticated, wasAuthenticated })
    if (isAuthenticated !== wasAuthenticated) {
      await setupAuth()
    }
  })

  function onError(newError: { message: string; name: string } | null) {
    if (newError) {
      const redirectURL = new URL(`${config.public.DASHBOARD_BASE_URL || 'http://localhost:3002'}`)
      redirectURL.searchParams.set('error', newError.name)
      redirectURL.searchParams.set('error_description', newError.message)
      console.error({ error: newError, redirectURL })
      navigateTo(redirectURL.href, { external: true })
    }
  }
  watch(auth0.error, onError)

  return {
    provide: {
      auth: {
        isLoggedIn,
        refreshTokenIfRequired,
      },
    },
  }
})
