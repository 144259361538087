import { defaultChapter, defaultSceneLayout } from 'adificial-common/dist/defaults'
import { CONTENT_TYPE, MEDIA_ORIGINS } from 'adificial-common/dist/enums/adstudio'

export default () => ({
  baseURL: '',
  apiError: false,
  userProfile: null,
  accountAccess: {
    enabled: false,
    id: null,
    name: '',
    originalId: '',
  },
  connections: [],
  selectedConnection: undefined,
  tab: '',
  chapter: undefined,
  firstTimer: {
    sceneLayouts: true,
  },
  mutateSceneContent: false,
  media: {
    stockImages: [],
    stockAudio: [],
    stockVideos: [],
    pexels: [],
    pexelsVideos: [],
    thirdPartyAudio: [],
    currentPage: 1,
    currentStockPage: 0,
    query: '',
    mediaToUpload: [],
    elementToReplace: undefined,
    availableAIVoices: [],
    lastTypeUpdates: '',
    activeTab: MEDIA_ORIGINS.COMPANY_VAULT,
  },
  dynamicImages: [],
  lastBGImage: undefined,
  lastBGVideo: undefined,
  lastBGColor: undefined,
  subMenu: '',
  isTextEditing: false,
  isEditingOptions: false,
  availableFonts: {
    googleFonts: undefined,
    gFPageSize: undefined,
    gFPage: undefined,
    customFonts: undefined,
  },
  sceneTransitionsConfig: {
    selectedSceneTransitionIndex: undefined,
  },
  contentType: CONTENT_TYPE.CHAPTER,
  isEditing: false,
  sceneLayout: defaultSceneLayout(),
  fontPage: 1,
  fontPageSize: 40,
  shouldRefresh: false,
  shouldRedirect: false,
  shouldAutosave: false,
  isSaving: false,
  brands: [],
  currentBrand: undefined,
  showProfileMenu: false,
  isResizing: false,
  initialContent: defaultChapter(),
  elementsCopied: [],
  zoom: {
    scaleX: 0.4,
    scaleY: 0.4,
    minScale: 0.2,
    maxScaleX: 1.7,
    maxScaleY: 1.7,
  },
  voiceOverState: {
    isLoading: false,
    errorMessage: '',
  },
  chapterAudioState: {
    isLoading: false,
    errorMessage: '',
  },
  editingDynamicImageIndex: -1,
  usePreviewDefaults: false,
  audioRequestID: null,
  audioRequest: null,
  playbackPending: 'none',
  needsToRegenerateAudio: false,
  showUnsavedChangesModal: null,
  unsavedVoiceOverChanges: null,
})
