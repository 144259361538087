import { DateTime } from 'luxon'
import { useToast, POSITION } from 'vue-toastification'
import defaultLandingPage from 'adificial-common/dist/defaults/defaultLandingPage'
import { useMergeJson } from '@/composables/useMergeJson'

const { mergeMissingKeys } = useMergeJson()

const headers = {
  Authorization: 'null',
  'Content-Type': 'application/json',
}

const toast = useToast()

export default {
  setToken({ commit }, { accessToken, expiresIn }) {
    const expiration = DateTime.now().plus({ seconds: expiresIn })
    commit('setAuth', { accessToken: `Bearer ${accessToken}`, expiration })
  },
  getPURL({ state, commit }, purlID) {
    const options = {
      method: 'GET',
      mode: 'cors',
      headers,
    }
    const url = `${state.baseURL}/deployments/getPURLObject/${purlID}`

    commit('global/setIsFetching', true, { root: 'true' })

    return fetch(url, options)
      .then((response) => response.json())
      .then(({ statusCode, purlObject }) => {
        if (statusCode === 201) {
          commit('setPURLData', purlObject)
        } else {
          toast.error('Error: PURL not found')
        }
      })
      .finally(() => {
        commit('global/setIsFetching', false, { root: 'true' })
      })
  },
  getLandingPage({ state, commit }, landingID) {
    const options = {
      method: 'POST',
      mode: 'cors',
      headers,
      body: JSON.stringify({
        id: landingID,
      }),
    }
    const url = `${state.baseURL}/landing-page/get`

    fetch(url, options)
      .then((response) => response.json())
      .then(({ data }) => {
        if (data.jsonObject) {
          // console.log({ data })
          if (typeof data.jsonObject === 'string') data.jsonObject = JSON.parse(data.jsonObject)
          const mergedLandingObject = mergeMissingKeys(data.jsonObject, defaultLandingPage())
          commit('setLandingPage', mergedLandingObject)
        }
        if (data.favicons) {
          commit('setFavicons', data.favicons)
        }
      })
      .catch((error) => {
        console.trace('Error fetching LP', { error })
        toast.error('Error: Landing Page not found')
      })
  },
  showToast(_, { message, type, position = POSITION.TOP_CENTER }) {
    toast(message, { type, position })
  },
}
