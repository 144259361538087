import revive_payload_client_4sVQNw7RlN from "/vercel/path0/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/vercel/path0/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/vercel/path0/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/vercel/path0/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/vercel/path0/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import components_plugin_KR1HBZs4kY from "/vercel/path0/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/vercel/path0/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import chunk_reload_client_UciE0i6zes from "/vercel/path0/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import auth_vT9JWWT9pN from "/vercel/path0/plugins/auth.ts";
import fonts_client_lvmIfrl4Xu from "/vercel/path0/plugins/fonts.client.ts";
import gtag_client_Zw8EQXNVTz from "/vercel/path0/plugins/gtag.client.ts";
import vue_toastification_client_F29MfCWaUJ from "/vercel/path0/plugins/vue-toastification.client.ts";
import vue_draggable_client_CtEMRI3Db3 from "/vercel/path0/plugins/vue-draggable.client.ts";
import vue_popper_DBDwqfvz9O from "/vercel/path0/plugins/vue-popper.ts";
import vue_next_masonry_client_ZU0oLD4d8N from "/vercel/path0/plugins/vue-next-masonry.client.ts";
import bootstrap_iconst_client_2R6t0QJrCq from "/vercel/path0/plugins/bootstrap-iconst.client.ts";
import tooltip_Di9mlKVbnI from "/vercel/path0/plugins/tooltip.ts";
import vue_click_outside_client_GOVKRQDFiZ from "/vercel/path0/plugins/vue-click-outside.client.ts";
import vue_debounce_client_Jnm59iiRo7 from "/vercel/path0/plugins/vue-debounce.client.ts";
import vue_infinite_scroll_client_FVXSRhT9FB from "/vercel/path0/plugins/vue-infinite-scroll.client.ts";
import vuex_client_Id1qg9saoL from "/vercel/path0/plugins/vuex.client.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  check_outdated_build_client_8vK7RkfGxZ,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  chunk_reload_client_UciE0i6zes,
  auth_vT9JWWT9pN,
  fonts_client_lvmIfrl4Xu,
  gtag_client_Zw8EQXNVTz,
  vue_toastification_client_F29MfCWaUJ,
  vue_draggable_client_CtEMRI3Db3,
  vue_popper_DBDwqfvz9O,
  vue_next_masonry_client_ZU0oLD4d8N,
  bootstrap_iconst_client_2R6t0QJrCq,
  tooltip_Di9mlKVbnI,
  vue_click_outside_client_GOVKRQDFiZ,
  vue_debounce_client_Jnm59iiRo7,
  vue_infinite_scroll_client_FVXSRhT9FB,
  vuex_client_Id1qg9saoL
]