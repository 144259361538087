import { nextTick } from 'vue'
import { v4 as uuidv4 } from 'uuid'
import { useToast } from 'vue-toastification'
import {
  CONTENT_TYPE,
  FILE_TYPE,
  MEDIA_ORIGINS,
  ELEMENT_TYPE,
  ELEMENT_STYLE,
  ELEMENT_SHAPE_TYPE,
  MEDIA_TYPES,
  ELEMENT_BACKGROUND_TYPE,
  TEXT_ELEMENT_STYLE,
  VOICEOVER_CONFIG,
  CORNER_RADIUS_UNITS_VALUES,
} from 'adificial-common/dist/enums/adstudio'
import { NONE_TRANSITION, defaultAudio } from 'adificial-common/dist/defaults'
import { getVideoDimensions } from 'adificial-common/dist/util/mediaUtils'
import { searchMediaByName } from './api'

const toast = useToast()

// Waits until DOM styles re render. See https://github.com/vuejs/vue/issues/9200
function doubleRafPromise() {
  return new Promise((resolve, reject) => {
    requestAnimationFrame(() => {
      requestAnimationFrame(resolve)
    })
  })
}

const mediaUtilities = {
  loadImgFromUrl(mediaUrl) {
    const imgElement = new Image()
    imgElement.src = mediaUrl
    return new Promise((resolve) => {
      imgElement.onload = () => {
        resolve(imgElement)
      }
    })
  },
  getVideoDimensionsOf(mediaUrl) {
    return new Promise((resolve) => {
      const video = document.createElement('video')
      function callback(e) {
        const height = e.target.videoHeight
        const width = e.target.videoWidth
        const duration = e.target.duration
        resolve({ height, width, duration })
      }
      video.addEventListener('loadedmetadata', callback, false)
      video.src = mediaUrl
    })
  },
  getAudioMetadata(mediaUrl) {
    return new Promise((resolve) => {
      const audio = new Audio()
      function callback(e) {
        const duration = e.target.duration
        resolve({ duration })
      }
      audio.addEventListener('loadedmetadata', callback, false)
      audio.src = mediaUrl
    })
  },
  async getFileFromUrl({ url, name, defaultType = 'image/jpeg' }) {
    const response = await fetch(url)
    const data = await response.blob()
    return new File([data], name, {
      type: data.type || defaultType,
    })
  },
  parseMediaUrl(mediaUrl) {
    const pathname = new URL(mediaUrl).pathname
    const filename = pathname.split('/').pop()
    const extension = filename.split('.').pop()
    return { pathname, filename, extension }
  },
}

const mediaActions = {
  setVideoElementVideo(
    { commit, getters },
    { elementId, videoInfo: { width, height, duration, name, src }, debug, isGeneratingNewBaseState },
  ) {
    const element = getters.getElementById(elementId)
    if (debug) console.log({ elementId, element, videoInfo: { width, height, duration, name, src } })
    if (element?.type && element?.type === ELEMENT_TYPE.VIDEO) {
      const updateAttributeObj = {
        element,
        attribute: 'file',
        attributeValue: {
          originalWidth: width,
          originalHeight: height,
          videoDuration: duration,
          fileName: name,
        },
      }

      commit('setElementAttribute', updateAttributeObj)

      commit('setElementAttribute', {
        element,
        attribute: 'src',
        attributeValue: src,
      })

      if (!!width && !!height) {
        const maxWidth = getters.chapter.width
        const maxHeight = getters.chapter.height

        const dim = getVideoDimensions(width, height, maxWidth, maxHeight)

        commit('setElementSize', {
          element,
          width: dim.width,
          height: dim.height,
        })
      }
    } else {
      const updateAttributeObj = {
        element,
        attribute: 'file',
        attributeValue: {
          originalWidth: width,
          originalHeight: height,
          videoDuration: duration,
          fileName: name,
        },
      }
      commit('setElementAttribute', updateAttributeObj)
      commit('setElementAttribute', {
        element,
        attribute: 'src',
        attributeValue: src,
      })
      commit('setElementAttribute', {
        element,
        attribute: 'background',
        attributeValue: {
          type: ELEMENT_BACKGROUND_TYPE.VIDEO,
          value: src,
        },
      })
      if (!isGeneratingNewBaseState && !src.includes('blob:')) {
        commit('setLastBG', {
          videoSrc: {
            src,
            fileObj: {
              originalWidth: width,
              originalHeight: height,
              videoDuration: duration,
              fileName: name,
            },
          },
        })
      }
    }
  },
  setImageElementImage(
    { commit, getters },
    { elementId, imageInfo: { naturalHeight, naturalWidth, name, src }, debug, isGeneratingNewBaseState },
  ) {
    const element = getters.getElementById(elementId)
    if (debug)
      console.log({
        elementId,
        element,
        imageInfo: { naturalHeight, naturalWidth, name, src },
        isGeneratingNewBaseState,
      })
    if (element?.type === ELEMENT_TYPE.IMAGE) {
      commit('setElementSize', {
        element,
        width: naturalWidth,
        height: naturalHeight,
      })

      const updateAttributeObj = {
        element,
        attribute: 'file',
        attributeValue: {
          originalWidth: naturalWidth,
          originalHeight: naturalHeight,
          fileName: name,
        },
      }

      commit('setElementAttribute', updateAttributeObj)

      commit('setElementAttribute', {
        element,
        attribute: 'src',
        attributeValue: src,
      })
    } else {
      // Background image
      const updateAttributeObj = {
        element,
        attribute: 'background',
        attributeValue: {
          type: ELEMENT_BACKGROUND_TYPE.IMAGE,
          value: src,
        },
      }
      if (!isGeneratingNewBaseState)
        commit('setLastBG', {
          imageSrc: {
            src,
            fileObj: {
              originalWidth: naturalWidth,
              originalHeight: naturalHeight,
              fileName: name,
            },
          },
        })
      if (debug) console.log(updateAttributeObj)
      commit('setElementAttribute', updateAttributeObj)
      commit('setElementAttribute', {
        element,
        attribute: 'file',
        attributeValue: {
          originalWidth: naturalWidth,
          originalHeight: naturalHeight,
          fileName: name,
          videoDuration: null,
        },
      })
      commit('setElementAttribute', {
        element,
        attribute: 'src',
        attributeValue: src,
      })
    }
  },
  setAudioElement({ commit, getters, dispatch }, { elementId, audioInfo, debug, isGeneratingNewBaseState }) {
    const element = getters.getElementById(elementId)
    if (debug) {
      console.log({
        elementId,
        element,
        audioInfo,
        isGeneratingNewBaseState,
      })
    }

    const isVoiceOver = getters.sceneVoiceOver?.id === elementId
    const chapterAudio = defaultAudio()

    if (!isVoiceOver) {
      chapterAudio.fadeOut = getters.chapter.audio?.fadeOut || false
      chapterAudio.audioAttenuation = getters.chapter.audio?.audioAttenuation || 0.8
    }

    chapterAudio.id = elementId
    chapterAudio.duration = audioInfo.duration
    chapterAudio.name = audioInfo.name
    chapterAudio.src = audioInfo.src

    isVoiceOver
      ? dispatch('setSceneVoiceOver', {
          voiceOver: chapterAudio,
          index: getters.voiceOverConfig?.[VOICEOVER_CONFIG.IS_CHAPTER_VOICEOVER]
            ? getters.voiceOverConfig?.[VOICEOVER_CONFIG.SCENE_VOICEOVER_INDEX]
            : getters.activeSceneIndex,
          persist: false,
        })
      : dispatch('setChapterAudio', { audio: chapterAudio, persist: false })
  },
  async addFileToUpload(
    { commit, dispatch, getters },
    { fileToUpload, elementId, debug, isUndoRedoAction, isGeneratingNewBaseState, isAudio },
  ) {
    const mb = 1e6
    const maxAllowedSize = 50 * mb

    if (fileToUpload.size > maxAllowedSize) {
      const message = 'Could not import media: File exceeds maximum size limit (50MB)'
      toast.error(message)
    } else {
      let element = getters.getElementById(elementId)
      const mediaConfig = getters.media
      const mediaToUpload = mediaConfig[MEDIA_TYPES.MEDIA_TO_UPLOAD]
      if (debug) console.log({ mediaToUploadSnapshot: JSON.parse(JSON.stringify(mediaToUpload)) })

      let mediaType

      if (isAudio) {
        mediaType = FILE_TYPE.AUDIO
        element = getters.sceneVoiceOver?.id === elementId ? getters.sceneVoiceOver : getters.chapter?.audio
        console.log(fileToUpload.src)
      } else {
        mediaType =
          element.type === ELEMENT_TYPE.VIDEO || element?.background?.type === ELEMENT_BACKGROUND_TYPE.VIDEO
            ? FILE_TYPE.VIDEO
            : FILE_TYPE.IMAGE
      }

      fileToUpload.mediaType = mediaType
      fileToUpload.elementToUpdate = element

      // Add file only if it is not already queded for upload
      const matchingFileToUpload = mediaToUpload.find((file) => file.name === fileToUpload.name)

      if (!matchingFileToUpload) {
        commit('setMedia', {
          type: MEDIA_TYPES.MEDIA_TO_UPLOAD,
          value: [...mediaToUpload, fileToUpload],
        })
        if (debug)
          console.log({
            newMediaToUploadSnapshot: JSON.parse(JSON.stringify(getters.media[MEDIA_TYPES.MEDIA_TO_UPLOAD])),
          })
      } else if (debug) {
        console.log('This file was already found. Not adding to the upload queue')
      }

      const newMediaUrl = URL.createObjectURL(fileToUpload)
      if (debug)
        console.log('addFileToUpload', {
          newMediaUrl,
          fileToUpload,
          matchingFileToUpload,
          mediaToUploadNames: mediaToUpload.map((m) => m.name),
        })
      switch (mediaType) {
        case FILE_TYPE.IMAGE: {
          const imageInfo = await mediaUtilities.loadImgFromUrl(newMediaUrl)
          imageInfo.name = fileToUpload.name
          dispatch('setImageElementImage', { elementId, imageInfo, debug, isUndoRedoAction, isGeneratingNewBaseState })
          break
        }
        case FILE_TYPE.VIDEO: {
          const metadata = await mediaUtilities.getVideoDimensionsOf(newMediaUrl)
          const videoInfo = {
            ...metadata,
            name: fileToUpload.name,
            src: newMediaUrl,
          }
          await dispatch('setVideoElementVideo', { elementId, videoInfo, isUndoRedoAction, isGeneratingNewBaseState })
          break
        }
        case FILE_TYPE.AUDIO: {
          const audioInfo = {
            ...(await mediaUtilities.getAudioMetadata(newMediaUrl)),
            name: fileToUpload.name,
            src: newMediaUrl,
          }

          await dispatch('setAudioElement', {
            elementId,
            audioInfo,
            debug,
            isUndoRedoAction,
            isGeneratingNewBaseState,
          })
          break
        }
      }

      if (!matchingFileToUpload) {
        dispatch('uploadPendingMedia')
      }
    }
  },
  async useThirdPartyMedia(
    { commit, dispatch },
    { elementId, media: { mediaUrl, mediaName }, debug, isUndoRedoAction, isGeneratingNewBaseState, isAudio },
  ) {
    try {
      const { filename, extension } = mediaUtilities.parseMediaUrl(mediaUrl)
      commit('global/setIsFetching', true, { root: true })
      if (debug) console.log('Downloading file to reupload to the company vault', { mediaUrl, mediaName, extension })
      const newThirdPartyFile = await mediaUtilities.getFileFromUrl({
        url: mediaUrl,
        name: `${mediaName}.${extension}`,
      })
      if (debug) console.log('Got file from URL', { newThirdPartyFile, extension, filename })
      commit('global/setIsFetching', false, { root: true })
      dispatch('addFileToUpload', {
        elementId,
        fileToUpload: newThirdPartyFile,
        debug,
        isUndoRedoAction,
        isGeneratingNewBaseState,
        isAudio,
      })
    } catch (error) {
      if (debug) console.log('Error occured while trying to use third party media', { error })
      throw error
    }
  },
}

export const actionsToTrack = {
  /* Tracked User Actions */
  setElementAttribute({ commit, getters, dispatch }, { elementId, attribute, attributeValue }) {
    const elementToUpdate = getters.getElementById(elementId)
    // console.log({ elementId, elementToUpdate, attribute, attributeValue })
    commit('setElementAttribute', { element: elementToUpdate, attribute, attributeValue })
    if (elementToUpdate.type === ELEMENT_TYPE.TEXT) {
      dispatch('correctTextContainer', { textElementId: elementId })
    }
    if (elementToUpdate.type !== ELEMENT_TYPE.BACKGROUND) dispatch('activateElement', elementId)
    else {
      dispatch('deactivateAll')
      commit('setActiveScene', getters.getElementSceneIndex(elementId))
    }
  },
  resetBackgroundDefaults({ commit, getters }, { elementId }) {
    const elementToUpdate = getters.getElementById(elementId)

    commit('setElementAttribute', {
      element: elementToUpdate,
      attribute: 'posX',
      attributeValue: 0,
    })
    commit('setElementAttribute', {
      element: elementToUpdate,
      attribute: 'posY',
      attributeValue: 0,
    })
    commit('setElementAttribute', {
      element: elementToUpdate,
      attribute: 'scale',
      attributeValue: 1,
    })
  },
  setChapterAttribute({ commit }, { attribute, attributeValue }) {
    commit('setChapterAttribute', { name: attribute, value: attributeValue })
  },
  setContentName({ commit, dispatch, getters }, { newName, persist, isUndoRedoAction }) {
    const contentType = getters.contentType
    if (contentType === CONTENT_TYPE.CHAPTER) {
      commit('setChapterAttribute', { name: 'chapterName', value: newName })
    } else {
      commit('setSceneLayoutAttribute', { name: 'sceneName', value: newName })
    }
    if (persist && newName !== '' && !isUndoRedoAction) dispatch('saveContent', { generateThumbnail: true })
  },
  setChapterSize({ commit }, { width, height }) {
    commit('setChapterSize', { width, height })
  },
  setSceneLayoutSize({ commit }, { width, height }) {
    commit('setSceneLayoutSize', { width, height })
  },
  setChapterAudio({ commit, state }, { audio, persist = false }) {
    if (audio && !audio.src?.includes('blob:')) {
      commit('setChapterAudioState', {
        isLoading: false,
        errorMessage: '',
      })
      commit('setChapterAudio', audio)
    }
  },
  setSelectedConnection({ commit }, { newConnectionId }) {
    commit('setSelectedConnection', newConnectionId)
  },
  setText({ commit, getters, dispatch }, { textElementId, newText, persist }) {
    const element = getters.getElementById(textElementId)
    if (element?.type === ELEMENT_TYPE.TEXT) {
      const updateAttributeHtml = {
        element,
        attributeValue: newText,
        attribute: 'html',
      }
      commit('setElementAttribute', updateAttributeHtml)

      const isDynamic = newText.includes('{{')
      const updateAttributeObj = {
        element,
        attributeValue: isDynamic,
        attribute: 'dynamic',
      }
      commit('setElementAttribute', updateAttributeObj)
      // Do not wait so this doesn't block the action from being completed
      if (persist) dispatch('correctTextContainer', { textElementId })
    }
  },

  setTextElementStyles({ commit, getters, dispatch }, { elementId, style, styleValue }) {
    const elementToUpdate = getters.getElementById(elementId)
    commit('setTextElementStyles', { element: elementToUpdate, style, styleValue })
    dispatch('activateElement', elementId)
    dispatch('correctTextContainer', { textElementId: elementId })
  },
  setSceneDuration({ commit }, { value, index }) {
    commit('setSceneDuration', { value, index })
  },
  reorderScenes({ commit, getters }, { scenesOrder }) {
    // scenesOrder is an array of Numbers in which the index of each number is the new position each scene should take and the number is the current index of the scene before the ordering takes place
    if (scenesOrder.length === getters.scenes.length) {
      const orderedScenes = scenesOrder.map((currentSceneIndex) => {
        return getters.scenes[currentSceneIndex]
      })
      commit('setScenes', orderedScenes)
    }
  },
  setSceneTransitionAtIndex({ commit }, { newSceneTransitionIndex, newSceneTransition }) {
    commit('setSceneTransitionAtIndex', { newSceneTransitionIndex, newSceneTransition })
  },
  setElementPosition({ commit, getters, dispatch }, { elementId, x, y }) {
    commit('setElementPosition', { element: getters.getElementById(elementId), x, y })
    dispatch('activateElement', elementId)
  },
  moveElement({ commit, dispatch, getters }, { action, elementId }) {
    const element = getters.getElementById(elementId)
    const scene = getters.scenes.find((scene) => scene.elements.find((el) => el.id === elementId))
    commit('moveElement', { action, element, scene })
    dispatch('activateElement', elementId)
  },
  setSceneElements({ commit, getters }, { sceneIndex, elements }) {
    let scene
    if (getters.contentType === CONTENT_TYPE.CHAPTER) {
      scene = getters.scenes[sceneIndex]
    }
    if (getters.contentType === CONTENT_TYPE.SCENE_LAYOUT) {
      scene = getters.activeScene
    }
    commit('setSceneElements', { scene, elements })
  },
  setElementSize({ commit, dispatch, getters }, { elementId, weigth, height }) {
    const element = getters.getElementById(elementId)
    commit('setElementSize', { element, weigth, height })
    dispatch('activateElement', elementId)
  },
  removeElementsFromList({ commit }, { elementsList, elementIds }) {
    commit('removeElementsFromList', { elementsList, elementIds })
  },
  addElementsToSceneAtIndex({ commit, getters, dispatch }, { sceneIndex, newElements }) {
    if (getters.contentType === CONTENT_TYPE.CHAPTER) {
      const scene = getters.scenes[sceneIndex]
      newElements.forEach((newElement) => commit('addElementToScene', { scene, newElement }))
      if (newElements.length) dispatch('activateElement', newElements[0].id)
    }
  },
  removeElementsFromSceneAtIndex({ commit, getters }, { elementIds, sceneIndex }) {
    switch (getters.contentType) {
      case CONTENT_TYPE.CHAPTER:
        commit('removeElementsFromList', { elementsList: getters.scenes[sceneIndex].elements, elementIds })
        commit('setActiveScene', sceneIndex)
        break
      case CONTENT_TYPE.SCENE_LAYOUT:
        commit('removeElementsFromList', { elementsList: getters.sceneLayout.elements, elementIds })
        break
      default:
        break
    }
  },
  addElementsToSceneLayout({ getters, dispatch, commit }, { newElements }) {
    if (getters.contentType === CONTENT_TYPE.SCENE_LAYOUT) {
      const scene = getters.sceneLayout
      newElements.forEach((newElement) => commit('addElementToScene', { scene, newElement }))
      if (newElements.length) dispatch('activateElement', newElements[0].id)
    }
  },
  addSceneAtIndex({ commit }, { index, newScene }) {
    commit('addSceneAtIndex', { index, newScene })
    commit('addSceneTransitionAtIndex', {
      newTransition: NONE_TRANSITION,
      index,
    })
    commit('setActiveScene', index)
  },
  updateSceneAtIndex({ commit }, { index, content }) {
    commit('changeSceneContentAtIndex', { indexToUpdate: index, newContent: content })
  },
  deleteScene({ getters, commit }, { index }) {
    const activeSceneIndex = getters.activeSceneIndex
    commit('removeScene', index)
    commit('removeTransition', index)
    commit('setActiveScene', activeSceneIndex)
  },
  setElementCornerRadius({ commit, getters, dispatch }, { elementId, newCornerRadius, newShapeType }) {
    const element = getters.getElementById(elementId)

    if (newCornerRadius !== undefined) {
      newCornerRadius = Number(newCornerRadius)
      const cornerRadiusUnit = element?.[ELEMENT_STYLE.CORNER_RADIUS_UNIT]
      const isPercentage = cornerRadiusUnit === CORNER_RADIUS_UNITS_VALUES.PERCENTAGE

      newShapeType = newCornerRadius < 100 || !isPercentage ? ELEMENT_SHAPE_TYPE.RECTANGLE : ELEMENT_SHAPE_TYPE.CIRCLE
    } else if (newShapeType !== undefined) {
      newCornerRadius = newShapeType === ELEMENT_SHAPE_TYPE.RECTANGLE ? 0 : 100
    }
    const updateAttributeObjs = []
    switch (element.type) {
      case ELEMENT_TYPE.SHAPE:
      case ELEMENT_TYPE.IMAGE:
        updateAttributeObjs.push({
          element,
          attributeValue: newCornerRadius,
          attribute: ELEMENT_STYLE.CORNER_RADIUS,
        })

        updateAttributeObjs.push({
          element,
          attributeValue: newShapeType,
          attribute: ELEMENT_STYLE.SHAPE_TYPE,
        })
        break
      default:
        break
    }
    updateAttributeObjs.forEach((updateAttributeObj) => commit('setElementAttribute', updateAttributeObj))
    dispatch('activateElement', elementId)
  },
  setBackgroundElementBackgroundType({ commit, getters, dispatch }, { elementId, newBGType, newValue }) {
    const element = getters.getElementById(elementId)
    commit('setElementAttribute', {
      element,
      attribute: 'background',
      attributeValue: {
        type: newBGType,
        value: newBGType !== ELEMENT_BACKGROUND_TYPE.COLOR ? newValue?.src : newValue,
      },
    })
    if (newBGType !== ELEMENT_BACKGROUND_TYPE.COLOR) {
      commit('setElementAttribute', {
        element,
        attribute: 'src',
        attributeValue: newValue?.src,
      })
      commit('setElementAttribute', {
        element,
        attribute: 'file',
        attributeValue: newValue?.fileObj,
      })
    }
    if (element.type === ELEMENT_TYPE.BACKGROUND) {
      const sceneIndexToActivate = getters.getElementSceneIndex(elementId)
      commit('setActiveScene', sceneIndexToActivate)
      dispatch('deactivateAll')
    }
  },
  setBackgroundElementBackgroundMedia({ commit, getters }, { elementId, newBackgroundMedia }) {
    const element = getters.getElementById(elementId)
    const backgroundData = element.background
    if (
      backgroundData.type === ELEMENT_BACKGROUND_TYPE.VIDEO ||
      backgroundData.type === ELEMENT_BACKGROUND_TYPE.IMAGE
    ) {
      commit('setElementAttribute', {
        element,
        attribute: 'background',
        attributeValue: {
          ...backgroundData,
          value: newBackgroundMedia,
        },
      })
      commit('setElementAttribute', {
        element,
        attribute: 'src',
        attributeValue: newBackgroundMedia,
      })
    }
  },
  setBackgroundElementColor({ commit, getters }, { elementId, newBackgroundColor }) {
    const element = getters.getElementById(elementId)
    const backgroundData = element.background
    if (backgroundData.type === ELEMENT_BACKGROUND_TYPE.COLOR) {
      commit('setElementAttribute', {
        element,
        attribute: 'background',
        attributeValue: {
          ...backgroundData,
          value: newBackgroundColor,
        },
      })
    }
    commit('setColorHistoryItem', newColor)
  },
  setElementsColor({ dispatch, commit, getters }, { elementIds, newColor, persist, isGeneratingNewBaseState }) {
    elementIds
      .map((elementId) => getters.getElementById(elementId))
      .forEach((element) => {
        let updateAttributeObj
        switch (element.type) {
          case ELEMENT_TYPE.BACKGROUND:
          case ELEMENT_TYPE.SHAPE:
            updateAttributeObj = {
              elementId: element.id,
              attributeValue: { type: ELEMENT_BACKGROUND_TYPE.COLOR, value: newColor },
              attribute: ELEMENT_STYLE.BACKGROUND,
              persist: false,
            }
            break
          case ELEMENT_TYPE.TEXT:
            dispatch('setTextElementStyles', {
              elementId: element.id,
              styleValue: newColor,
              style: TEXT_ELEMENT_STYLE.COLOR,
              persist: false,
            })
            break
          default:
            break
        }
        if (updateAttributeObj) {
          dispatch('setElementAttribute', updateAttributeObj)
        }
      })
    // Assumes all elements being changed are part of the same scene
    const firstElement = getters.getElementById(elementIds?.[0])
    if (firstElement?.type === ELEMENT_TYPE.BACKGROUND) {
      if (!isGeneratingNewBaseState) commit('setLastBG', { color: newColor })
      const sceneIndexToActivate = getters.getElementSceneIndex(firstElement.id)
      commit('setActiveScene', sceneIndexToActivate)
      dispatch('deactivateAll')
    } else {
      dispatch('activateElement', firstElement.id)
    }
    if (persist) commit('setColorHistoryItem', newColor)
  },
  setElementsShadow({ commit, dispatch }, { elementIds, newX, newY, newBlur, newColor, addToColorHistory }) {
    elementIds
      // .map((elementId) => getters.getElementById(elementId))
      .forEach((elementId) => {
        const updateAttributeObj = {
          elementId,
          attribute: ELEMENT_STYLE.SHADOW,
          attributeValue: {
            x: newX,
            y: newY,
            color: newColor,
            blur: newBlur,
          },
          persist: false,
        }
        dispatch('setElementAttribute', updateAttributeObj)
      })
    if (addToColorHistory && newColor) commit('setColorHistoryItem', newColor)
  },
  setElementsOutline({ dispatch, commit }, { elementIds, newOutlineColor, newOutlineSize, addToColorHistory }) {
    elementIds.forEach((elementId) => {
      const updateAttributeObj = {
        elementId,
        attributeValue: {
          color: newOutlineColor,
          size: newOutlineSize,
        },
        attribute: ELEMENT_STYLE.OUTLINE,
        persist: false,
      }

      dispatch('setElementAttribute', updateAttributeObj)
      if (addToColorHistory && newOutlineColor) commit('setColorHistoryItem', newOutlineColor)
    })
  },
  async setElementMedia(
    { commit, getters, dispatch, state },
    { elementId, mediaOrigin, media, file, isUndoRedoAction, isGeneratingNewBaseState, isAudio },
  ) {
    const debug = false
    let mediaUrl = media?.mediaUrl || ''
    let mediaName = media?.mediaName || ''

    const mediaToUpload = getters.media[MEDIA_TYPES.MEDIA_TO_UPLOAD]
    let elementToChange = getters.getElementById(elementId)
    let mediaType

    if (isAudio) {
      mediaType = FILE_TYPE.AUDIO
      elementToChange = getters.sceneVoiceOver?.id === elementId ? getters.sceneVoiceOver : getters.chapter?.audio
    } else {
      mediaType =
        elementToChange.type === ELEMENT_TYPE.VIDEO ||
        elementToChange?.background?.type === ELEMENT_BACKGROUND_TYPE.VIDEO
          ? FILE_TYPE.VIDEO
          : FILE_TYPE.IMAGE
    }

    // Re-adapting action since it is being re-done and the media could already be uploaded to the backend.
    if ([MEDIA_ORIGINS.THIRD_PARTY, MEDIA_ORIGINS.UPLOAD].includes(mediaOrigin)) {
      let currentMediaName
      if (mediaOrigin === MEDIA_ORIGINS.THIRD_PARTY) {
        const { extension } = mediaUtilities.parseMediaUrl(mediaUrl)
        currentMediaName = `${mediaName}.${extension}`
      } else if (mediaOrigin === MEDIA_ORIGINS.UPLOAD) {
        currentMediaName = file.name
      }

      const mediaTypesByFileType = {
        [FILE_TYPE.IMAGE]: MEDIA_TYPES.STOCK_IMAGES,
        [FILE_TYPE.VIDEO]: MEDIA_TYPES.STOCK_VIDEOS,
        [FILE_TYPE.AUDIO]: MEDIA_TYPES.STOCK_AUDIO,
      }

      let matchingMediaInCompanyAssets = getters?.media[mediaTypesByFileType[mediaType]]?.find(
        (media) => media.mediaName === currentMediaName,
      )

      if (!matchingMediaInCompanyAssets) {
        await this.$auth.refreshTokenIfRequired()
        matchingMediaInCompanyAssets = await searchMediaByName(
          { state, commit, getters },
          { query: currentMediaName, mediaType },
        )
      }

      // Add file only if it is not already queded for upload
      const matchingFileToUpload = mediaToUpload.find((file) => file.name === currentMediaName)

      // Discard previous medias
      const mediaToPreserve = []
      let mediaToDiscard
      for (const mToUpload of mediaToUpload) {
        if (
          mToUpload.elementToUpdate.id === elementToChange.id &&
          mToUpload.mediaType === mediaType &&
          mToUpload.name !== currentMediaName
        )
          mediaToDiscard = mToUpload
        else mediaToPreserve.push(mToUpload)
      }
      if (mediaToDiscard) {
        URL.revokeObjectURL(elementToChange.src)
      }
      commit('setMedia', {
        type: MEDIA_TYPES.MEDIA_TO_UPLOAD,
        value: mediaToPreserve,
      })

      if (debug)
        console.log(
          'Seeking for already uploaded media (or queded to upload) that matches the one the action is currently trying to upload',
          { currentMediaName, matchingMediaInCompanyAssets, matchingFileToUpload },
        )
      if (debug) console.log('Found matching media?', { matchingMedia: matchingMediaInCompanyAssets })
      if (matchingMediaInCompanyAssets) {
        mediaOrigin = MEDIA_ORIGINS.COMPANY_VAULT
        mediaUrl = matchingMediaInCompanyAssets.mediaUrl
        mediaName = matchingMediaInCompanyAssets.mediaName
      }
    }
    if (debug) console.log({ elementId, media: { mediaUrl, mediaName, mediaOrigin, mediaType } })
    switch (mediaOrigin) {
      case MEDIA_ORIGINS.COMPANY_VAULT: // Company Vault
        switch (mediaType) {
          case FILE_TYPE.IMAGE: {
            const imageInfo = await mediaUtilities.loadImgFromUrl(mediaUrl)
            imageInfo.name = mediaName
            if (debug) console.log({ imageInfo })
            await dispatch('setImageElementImage', {
              imageInfo,
              elementId,
              debug,
              isUndoRedoAction,
              isGeneratingNewBaseState,
            })
            break
          }
          case FILE_TYPE.VIDEO: {
            const videoInfo = {
              ...(await mediaUtilities.getVideoDimensionsOf(mediaUrl)),
              name: mediaName,
              src: mediaUrl,
            }
            await dispatch('setVideoElementVideo', {
              elementId,
              videoInfo,
              debug,
              isUndoRedoAction,
              isGeneratingNewBaseState,
            })
            break
          }
          case FILE_TYPE.AUDIO: {
            const audioInfo = {
              ...(await mediaUtilities.getAudioMetadata(mediaUrl)),
              name: mediaName,
              src: mediaUrl,
            }

            // setAudioElement
            await dispatch('setAudioElement', {
              elementId,
              audioInfo,
              debug,
              isUndoRedoAction,
              isGeneratingNewBaseState,
            })
            break
          }
        }
        break
      case MEDIA_ORIGINS.THIRD_PARTY: // Stock images / Third-party
        if (debug) console.log('Using third party image:', { elementId, media: { mediaUrl, mediaName, debug } })
        await dispatch('useThirdPartyMedia', {
          elementId,
          media: { mediaUrl, mediaName },
          debug,
          isUndoRedoAction,
          isGeneratingNewBaseState,
          isAudio,
        })
        break
      case MEDIA_ORIGINS.UPLOAD: // Direct upload
        await dispatch('addFileToUpload', {
          elementId,
          fileToUpload: file,
          debug,
          isUndoRedoAction,
          isGeneratingNewBaseState,
          isAudio,
        })
        break
    }

    if (elementToChange?.type === ELEMENT_TYPE.BACKGROUND) {
      const sceneIndexToActivate = getters.getElementSceneIndex(elementId)
      commit('setActiveScene', sceneIndexToActivate)
      dispatch('deactivateAll')
    } else {
      !isAudio && dispatch('activateElement', elementToChange.id)
    }
    if (!isUndoRedoAction)
      nextTick(() => {
        commit('setSubMenu', '')
      })
    if (debug) console.log('Done setElementMedia', { isUndoRedoAction })
  },
  setElementAnimationAtIndex(
    { getters, commit, dispatch },
    { elementId, newAnimation, newAnimationIndex, replace, isUndoRedoAction, shouldPlayPreview },
  ) {
    // console.log({ elementId, newAnimation, newAnimationIndex, replace, isUndoRedoAction, shouldPlayPreview })
    const element = getters.getElementById(elementId)
    commit('setElementAnimationAtIndex', {
      element,
      index: newAnimationIndex,
      ...(newAnimation && { newAnimation }),
      replace,
    })
    if (!isUndoRedoAction && newAnimation?.effect !== 'none' && shouldPlayPreview) {
      return new Promise((resolve) => {
        commit(
          'adstudio/startElementPreview',
          { element, onPreviewEnd: resolve, previewAnimationIndex: newAnimationIndex },
          { root: true },
        )
      })
    }
    if (element.type !== ELEMENT_TYPE.BACKGROUND) dispatch('activateElement', elementId)
  },
  resizeElement(
    { getters, commit, dispatch },
    { elementId, newFontSize, newLineHeight, newX, newY, newWidth, newHeight, isUndoRedoAction, isLastAction, persist },
  ) {
    const element = getters.getElementById(elementId)
    if (newFontSize !== undefined) {
      commit('setTextElementStyles', {
        element,
        styleValue: newFontSize,
        style: TEXT_ELEMENT_STYLE.FONT_SIZE,
      })
    }
    if (newLineHeight !== undefined) {
      commit('setTextElementStyles', {
        element,
        styleValue: newLineHeight,
        style: TEXT_ELEMENT_STYLE.LINE_HEIGHT,
      })
    }
    commit('setElementSizeAndPosition', {
      element,
      x: newX,
      y: newY,
      width: newWidth,
      height: newHeight,
    })

    if (persist && element.type === ELEMENT_TYPE.TEXT) dispatch('correctTextContainer', { textElementId: elementId })
    if (isUndoRedoAction) {
      dispatch('activateElement', elementId)
    }
  },
  setElementLock({ getters, commit }, { elementId, newLockValue }) {
    const element = getters.getElementById(elementId)
    commit('setElementAttribute', { element, attribute: ELEMENT_STYLE.LOCK, attributeValue: newLockValue })
    if (newLockValue) commit('deactivateElement', element)
  },
  setElementVisible({ getters, commit }, { elementId, newVisibleValue }) {
    const element = getters.getElementById(elementId)
    commit('setElementAttribute', { element, attribute: ELEMENT_STYLE.VISIBLE, attributeValue: newVisibleValue })
    if (!newVisibleValue) commit('deactivateElement', element)
  },
  setDatapointDefaultValues({ commit }, { datapointDefaultValues }) {
    commit('setDatapointDefaultValues', datapointDefaultValues)
  },
  /* End of User Actions */
}

export const actionsToTrackAndHide = {
  setActiveScene({ commit }, sceneIndex) {
    commit('setActiveScene', sceneIndex)
  },
  activateElement({ commit, getters, dispatch }, elementId) {
    const element = getters.getElementById(elementId)
    commit('activateElement', element)

    dispatch('deactivateAllElementsBut', element)
    if (getters.scenes?.length) {
      const sceneIndexToActivate = getters.getElementSceneIndex(elementId)
      commit('setActiveScene', sceneIndexToActivate)
    }
  },
}

export default {
  ...actionsToTrack,
  ...actionsToTrackAndHide,
  ...mediaActions,
  addNewScene({ getters, dispatch }, newScene) {
    dispatch('addSceneAtIndex', { newScene, index: getters.scenes.length })
  },
  updateScene({ getters, dispatch }, { index, content }) {
    dispatch('updateSceneAtIndex', { index, content })
  },
  addElement({ getters, dispatch }, newElement) {
    switch (getters.contentType) {
      case CONTENT_TYPE.CHAPTER:
        dispatch('addElementsToSceneAtIndex', { sceneIndex: getters.activeSceneIndex, newElements: [newElement] })
        break
      case CONTENT_TYPE.SCENE_LAYOUT:
        dispatch('addElementsToSceneLayout', { newElements: [newElement] })
        break
      default:
        break
    }
    dispatch('activateElement', newElement.id)
  },
  duplicateActiveSceneElements({ dispatch, getters }) {
    const scene = getters.activeScene
    const clones = scene.elements
      .filter((originalElement) => originalElement.active && originalElement.type !== ELEMENT_TYPE.BACKGROUND)
      .map((originalElement) => {
        const clone = JSON.parse(JSON.stringify(originalElement))
        clone.id = uuidv4()
        clone.active = false
        clone.x += 10
        clone.y += 10
        return clone
      })

    switch (getters.contentType) {
      case CONTENT_TYPE.CHAPTER:
        dispatch('addElementsToSceneAtIndex', { sceneIndex: getters.activeSceneIndex, newElements: clones })
        break
      case CONTENT_TYPE.SCENE_LAYOUT:
        dispatch('addElementsToSceneLayout', { newElements: clones })
        break
      default:
        break
    }
    if (clones.length) dispatch('activateElement', clones[0].id)
  },
  duplicateScene({ dispatch, getters }, sceneIndexToClone) {
    const sceneToClone = getters.scenes[sceneIndexToClone]
    const clonedElements = []
    if (sceneToClone) {
      const newScene = JSON.parse(JSON.stringify(sceneToClone))
      sceneToClone.elements.forEach((element) => {
        const clone = JSON.parse(JSON.stringify(element))
        clone.id = uuidv4()
        clonedElements.push(clone)
      })

      newScene.elements = clonedElements
      newScene.id = uuidv4()
      dispatch('addSceneAtIndex', { index: sceneIndexToClone, newScene })
    }
  },
  resetContent({ commit }, payload = {}) {
    const { newStateSnapshot: newContentSnapshot } = payload || {}
    commit('resetContent', { newContentSnapshot })
  },
  updateInitialContent({ commit, getters }, payload = {}) {
    const { newInitialState: updatedContent } = payload || {}
    const updatedContents = {
      [CONTENT_TYPE.CHAPTER]: getters.chapter,
      [CONTENT_TYPE.SCENE_LAYOUT]: getters.sceneLayout,
    }
    commit('setInitialContent', updatedContent || updatedContents[getters.contentType])
  },
  zoomAction({ commit, getters }, { zoomActionType }) {
    let { scaleX, scaleY } = getters.zoom
    const { maxScaleX, maxScaleY, minScale } = getters.zoom
    const contentScale = getters.content?.transform?.scale

    switch (zoomActionType) {
      case 'in':
        if (scaleX < maxScaleX && scaleY < maxScaleY) {
          scaleX += 0.1
          scaleY += 0.1

          if (scaleX > maxScaleX || scaleY > maxScaleY) {
            // console.log('scale too big, reducing to maxScale', { scaleX, scaleY, maxScaleX, maxScaleY })
            if (maxScaleX <= maxScaleY) {
              scaleX = maxScaleX
              scaleY = scaleX
            } else {
              scaleY = maxScaleY
              scaleX = scaleY
            }
          }
        }
        break
      case 'out':
        if (scaleX > minScale && scaleY > minScale) {
          scaleX -= 0.1
          scaleY -= 0.1
        }
        break
      case 'fit': {
        if (maxScaleX <= maxScaleY) {
          scaleX = maxScaleX
          scaleY = scaleX
        } else {
          scaleY = maxScaleY
          scaleX = scaleY
        }
        break
      }
      case 'normal':
        scaleX = contentScale
        scaleY = scaleX
        break
    }
    commit('setZoom', { ...getters.zoom, scaleX, scaleY })
  },
  correctTextContainer({ commit, getters }, { textElementId }) {
    return doubleRafPromise().then(() => {
      // console.log('handling text resize')
      const textElement = getters.getElementById(textElementId)
      const textElementContainer = document.querySelector('#AD_text_' + textElement.id)

      let newWidth, newHeight

      if (textElementContainer.childNodes.length === 0 || textElementContainer.children.item(0)?.tagName === 'BR') {
        const minElementWidthPx = 100
        const minElementHeightPx = 100
        newWidth = minElementWidthPx
        newHeight = minElementHeightPx
      } else {
        // Check if the content width could be reduced
        textElementContainer.style.height = 'auto'
        textElementContainer.style.width = 'max-content'

        // const minWidthNoWrap = Math.max(
        //   parseFloat(getComputedStyle(textElementContainer).width.replace('px', '')),
        //   minElementWidthPx,
        // )

        const minWidthNoWrap = parseFloat(getComputedStyle(textElementContainer).width.replace('px', ''))

        // const minHeightNoWrap = parseFloat(getComputedStyle(textElementContainer).height.replace('px', ''))

        // console.log({ minHeightNoWrap, minWidthNoWrap })

        // Turning off width reduction to allow for dynamic data longer than the dynamic tag it replaces

        /* if (minWidthNoWrap > textElement.width) {
          // The text includes wraps (smaller than min-content)
          // console.log('The text includes wraps (smaller than min-content)')
          newWidth = textElement.width
          textElementContainer.style.removeProperty('width')
          newHeight = textElementContainer.scrollHeight
        } else {
          // Element is wider than necessary. Reducing
          // console.log('Element is wider than necessary. Reducing')
          newWidth = minWidthNoWrap
          textElementContainer.style.removeProperty('width')
          newHeight = textElementContainer.scrollHeight
        }

        textElementContainer.style.removeProperty('height')
        textElementContainer.style.removeProperty('width') */
        newWidth = textElement.width
        textElementContainer.style.removeProperty('width')
        newHeight = textElementContainer.scrollHeight
      }

      // console.log({
      //   elementHeight: textElement.height,
      //   newHeight,
      //   elementWidth: textElement.width,
      //   newWidth,
      // })

      commit('setElementSizeAndPosition', {
        element: textElement,
        width: newWidth,
        height: newHeight,
        x: textElement.x,
        y: textElement.y,
      })
    })
  },
}
