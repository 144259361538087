import { ActionContext, Store } from 'vuex'
interface Action {
  type: string
  payload: any
}

type ActionSnapshot = string | any

interface RecordedAction {
  actionSnapshot: ActionSnapshot
  hide?: boolean
  onUndo?: Action
  onRedo?: Action
  serialize?: (action: Action) => ActionSnapshot
  deserialize?: (actionSnapshot: ActionSnapshot) => Action
}

interface ActionToTrack {
  type: string
  hide?: boolean
  onUndo?: Action
  onRedo?: Action
  serialize?: (action: Action) => ActionSnapshot
  deserialize?: (actionSnapshot: ActionSnapshot) => Action
}

interface ActionStoreState {
  actionHistory: RecordedAction[]
  performingActions: boolean
  currentActionHistoryIndex: number | undefined
}

interface createUndoRediPluginConfig {
  parentModuleName: string
  resetStateActionName: string
  actionsToTrack: ActionToTrack[]
  historySize?: number
  updateInitialStateActionName?: string
  stateGetter?: string
  debug?: boolean
}

export function createUndoRedoPlugin({
  parentModuleName,
  resetStateActionName,
  actionsToTrack,
  historySize,
  updateInitialStateActionName,
  stateGetter,
  debug,
}: createUndoRediPluginConfig) {
  return (vuexStore: Store<any>) => {
    // Actions dynamic module set up
    const actionsModuleConfig = {
      namespaced: true,
      state(): ActionStoreState {
        return {
          actionHistory: [],
          performingActions: false,
          currentActionHistoryIndex: undefined,
        }
      },
      getters: {
        canUndoAction(state: ActionStoreState, getters: any) {
          return (
            getters.visibleCurrentActionHistoryIndex !== undefined &&
            getters.visibleCurrentActionHistoryIndex >= 0 &&
            !state.performingActions
          )
        },
        canRedoAction(state: ActionStoreState, getters: any) {
          return (
            (getters.visibleCurrentActionHistoryIndex !== undefined
              ? getters.visibleCurrentActionHistoryIndex < getters.visibleActionHistory.length - 1
              : !!getters.visibleActionHistory.length) && !state.performingActions
          )
        },
        parsedActionHistory(state: ActionStoreState) {
          // return state.actionHistory.map((recordedAction) => JSON.parse(recordedAction.actionSnapshot))
          return state.actionHistory.map((recordedAction) => {
            const deserialize = recordedAction.deserialize ?? JSON.parse
            return deserialize(recordedAction.actionSnapshot)
          })
        },
        visibleActionHistory(state: ActionStoreState) {
          return state.actionHistory.filter((recordedAction) => !recordedAction.hide)
        },
        visibleCurrentActionHistoryIndex(state: ActionStoreState, getters: any) {
          return getters.getVisibleActionHistoryIndex(state.currentActionHistoryIndex)
        },
        getRawActionHistoryIndex(state: ActionStoreState, getters: any) {
          return (visibleIndex: number | undefined) => {
            if (visibleIndex === undefined) return undefined
            else {
              const index = state.actionHistory.indexOf(getters.visibleActionHistory[visibleIndex])
              return index === -1 ? undefined : index
            }
          }
        },
        getVisibleActionHistoryIndex(state: ActionStoreState, getters: any) {
          return (rawIndex: number | undefined) => {
            if (rawIndex === undefined) return undefined
            else {
              const actionAtRawIndex = state.actionHistory[rawIndex]
              let visibleIndex
              if (!actionAtRawIndex.hide)
                visibleIndex = getters.visibleActionHistory.findIndex(
                  (visibleAction: RecordedAction) => visibleAction === actionAtRawIndex,
                )
              else {
                for (let rIndex = 0; rIndex < state.actionHistory.length && rIndex <= rawIndex; rIndex++) {
                  if (!state.actionHistory[rIndex].hide)
                    visibleIndex = visibleIndex === undefined ? 0 : visibleIndex + 1
                }
              }
              return visibleIndex
            }
          }
        },
        isPerformingActions(state: ActionStoreState) {
          return state.performingActions
        },
      },
      mutations: {
        setActionHistory(state: ActionStoreState, actionHistory: RecordedAction[]) {
          state.actionHistory = actionHistory
        },
        setPerformingActions(state: ActionStoreState, performingActions: boolean) {
          state.performingActions = performingActions
        },
        setCurrentActionHistoryIndex(state: ActionStoreState, currentActionHistoryIndex: number) {
          state.currentActionHistoryIndex = currentActionHistoryIndex
        },
      },
      actions: {
        async undo({ dispatch, state, commit, getters }: ActionContext<ActionStoreState, any>) {
          // if (state.currentActionHistoryIndex !== undefined) {
          //   dispatch(resetStateActionName, null, { root: true })
          //   commit('setPerformingActions', true)
          //   if (debug)
          //     console.log(
          //       `Re-perfoming ${
          //         state.currentActionHistoryIndex !== undefined ? state.currentActionHistoryIndex + 1 : 'no'
          //       } actions`,
          //     )
          //   state.actionHistory.slice(0, (state.currentActionHistoryIndex ?? -1) + 1).forEach((recordedAction) => {
          //     const action = JSON.parse(recordedAction.actionSnapshot)
          //     if (debug) console.log('Re-performing action', { action })
          //     dispatch(action.type, action.payload, { root: true })
          //   })
          //   commit('setPerformingActions', false)
          // }
          const currentVisibleIndex = getters.getVisibleActionHistoryIndex(state.currentActionHistoryIndex)
          if (debug)
            console.log('Performing Undo: ', {
              currentActionHistoryIndex: state.currentActionHistoryIndex,
              currentVisibleIndex,
            })
          if (currentVisibleIndex !== undefined) {
            const newVisibleIndex = currentVisibleIndex - 1 >= 0 ? currentVisibleIndex - 1 : undefined
            // const newRawIndex =
            //   newVisibleIndex === getters.visibleActionHistory.length - 1
            //     ? state.actionHistory.length - 1
            //     : getters.getRawActionHistoryIndex((newVisibleIndex ?? -1) + 1) - 1
            const newRawIndex =
              newVisibleIndex === undefined
                ? getters.getRawActionHistoryIndex(currentVisibleIndex) - 1 >= 0
                  ? getters.getRawActionHistoryIndex(currentVisibleIndex) - 1
                  : undefined
                : getters.getRawActionHistoryIndex(newVisibleIndex + 1) - 1
            if (debug) console.log({ newVisibleIndex, newRawIndex })
            commit('setPerformingActions', true)
            await dispatch(resetStateActionName, null, { root: true })
            if (debug) {
              console.log(`Re-perfoming ${newRawIndex !== undefined ? newRawIndex + 1 : 'no'} total actions`)
              console.log(`Re-perfoming ${newVisibleIndex !== undefined ? newVisibleIndex + 1 : 'no'} visible actions`)
            }
            // state.actionHistory.slice(0, (newRawIndex ?? -1) + 1).forEach((recordedAction) => {
            //   const deserialize = recordedAction.deserialize ?? JSON.parse
            //   const action = deserialize(recordedAction.actionSnapshot)
            //   if (debug) console.log('Re-performing action', { action })
            //   dispatch(
            //     action.type,
            //     {
            //       ...action.payload,
            //       isUndoRedoAction: true,
            //     },
            //     { root: true },
            //   )
            // })
            const actionsToRedo = state.actionHistory.slice(0, (newRawIndex ?? -1) + 1)
            for (const recordedAction of actionsToRedo) {
              const deserialize = recordedAction.deserialize ?? JSON.parse
              const action = deserialize(recordedAction.actionSnapshot)
              if (debug) console.log('Re-performing action', { action })
              await dispatch(
                action.type,
                {
                  ...action.payload,
                  isUndoRedoAction: true,
                  isLastAction: recordedAction === actionsToRedo[actionsToRedo.length - 1],
                },
                { root: true },
              )
            }
            if (state.currentActionHistoryIndex !== undefined) {
              const actionToUndo = state.actionHistory[state.currentActionHistoryIndex]
              const onUndoAction = actionToUndo?.onUndo
              if (debug) console.log({ onUndoAction })
              if (onUndoAction) {
                if (typeof onUndoAction.payload === 'function') {
                  await dispatch(onUndoAction.type, onUndoAction.payload({ action: actionToUndo }), { root: true })
                } else {
                  await dispatch(onUndoAction.type, onUndoAction.payload, { root: true })
                }
              }
            }
            commit('setPerformingActions', false)
            commit('setCurrentActionHistoryIndex', newRawIndex)
          }
        },
        async redo({ dispatch, commit, state, getters }: ActionContext<ActionStoreState, any>) {
          // if (
          //   state.currentActionHistoryIndex === undefined ||
          //   state.currentActionHistoryIndex < state.actionHistory.length - 1
          // ) {
          //   commit('setPerformingActions', true)
          //   dispatch(resetStateActionName, null, { root: true })
          //   commit('setCurrentActionHistoryIndex', (state.currentActionHistoryIndex ?? -1) + 1)
          //   if (debug)
          //     console.log(
          //       `Re-perfoming ${
          //         state.currentActionHistoryIndex !== undefined ? state.currentActionHistoryIndex + 1 : 'no'
          //       } actions`,
          //     )
          //   state.actionHistory.slice(0, (state.currentActionHistoryIndex ?? -1) + 1).forEach((recordedAction) => {
          //     const action = JSON.parse(recordedAction.actionSnapshot)
          //     if (debug) console.log('Re-performing action', { action })
          //     dispatch(action.type, action.payload, { root: true })
          //   })
          //   commit('setPerformingActions', false)
          // }
          const currentVisibleIndex = getters.getVisibleActionHistoryIndex(state.currentActionHistoryIndex)
          if (currentVisibleIndex === undefined || currentVisibleIndex < getters.visibleActionHistory.length - 1) {
            commit('setPerformingActions', true)
            await dispatch(resetStateActionName, null, { root: true })
            const newVisibleIndex = currentVisibleIndex === undefined ? 0 : currentVisibleIndex + 1
            // const newRawIndex = getters.getRawActionHistoryIndex(newVisibleIndex)
            const newRawIndex =
              newVisibleIndex === getters.visibleActionHistory.length - 1
                ? state.actionHistory.length - 1
                : getters.getRawActionHistoryIndex(newVisibleIndex + 1) - 1
            if (debug) {
              console.log(`Re-perfoming ${newRawIndex !== undefined ? newRawIndex + 1 : 'no'} actions`)
              console.log(`Re-perfoming ${newVisibleIndex !== undefined ? newVisibleIndex + 1 : 'no'} visible actions`)
            }
            // state.actionHistory.slice(0, newRawIndex + 1).forEach((recordedAction) => {
            //   const deserialize = recordedAction.deserialize ?? JSON.parse
            //   const action = deserialize(recordedAction.actionSnapshot)
            //   if (debug) console.log('Re-performing action', { action })
            //   await dispatch(
            //     action.type,
            //     {
            //       ...action.payload,
            //       isUndoRedoAction: true,
            //     },
            //     { root: true },
            //   )
            // })
            const actionsToRedo = state.actionHistory.slice(0, newRawIndex + 1)
            for (const recordedAction of actionsToRedo) {
              const deserialize = recordedAction.deserialize ?? JSON.parse
              const action = deserialize(recordedAction.actionSnapshot)
              if (debug) console.log('Re-performing action', { action })
              await dispatch(
                action.type,
                {
                  ...action.payload,
                  isUndoRedoAction: true,
                  isLastAction: recordedAction === actionsToRedo[actionsToRedo.length - 1],
                },
                { root: true },
              )
            }

            const actionToRedo = state.actionHistory[newRawIndex]
            const onRedoAction = actionToRedo?.onRedo
            if (debug) console.log({ onRedoAction })
            if (onRedoAction) {
              if (typeof onRedoAction.payload === 'function') {
                await dispatch(onRedoAction.type, onRedoAction.payload({ action: actionToRedo }), { root: true })
              } else {
                await dispatch(onRedoAction.type, onRedoAction.payload, { root: true })
              }
            }

            commit('setPerformingActions', false)
            commit('setCurrentActionHistoryIndex', newRawIndex)
          }
        },
        async logAction(
          { commit, state, dispatch, rootGetters }: ActionContext<ActionStoreState, any>,
          {
            action,
            hide,
            onUndo,
            onRedo,
            serialize,
            deserialize,
          }: {
            action: Action
            hide: boolean
            onUndo?: string
            onRedo?: string
            serialize?: (action: Action) => ActionSnapshot
            deserialize?: (actionSnapshot: ActionSnapshot) => Action
          },
        ) {
          serialize ??= JSON.stringify
          const actionSnapshot = serialize(action)
          let newActionHistory
          let newCurrentActionHistoryIndex
          if (state.currentActionHistoryIndex === undefined) {
            newActionHistory = [{ actionSnapshot, hide, onUndo, onRedo, serialize, deserialize }]
            newCurrentActionHistoryIndex = 0
          } else {
            newActionHistory = [
              ...state.actionHistory.slice(0, state.currentActionHistoryIndex + 1),
              { actionSnapshot, hide, onUndo, onRedo, serialize, deserialize },
            ]
            newCurrentActionHistoryIndex = state.currentActionHistoryIndex + 1

            const visibleTrackedActions = newActionHistory.filter((recordedAction) => !recordedAction.hide)

            if (historySize !== undefined && historySize !== 0 && updateInitialStateActionName !== undefined) {
              if (debug) console.log('visibleTrackedActions', visibleTrackedActions)
              // if (visibleTrackedActions.length > historySize) {
              //   if (debug) console.log('Too many visible actions. Shifting action history stack')
              //   await dispatch(resetStateActionName, null, { root: true })
              //   // const newInitialActionIndex = newActionHistory.findIndex((recordedAction) => !recordedAction.hide) + 1
              //   const newInitialActionRawIndex =
              //     newActionHistory.findIndex((recordedAction) => !recordedAction.hide) + 1
              //   if (debug) console.log('newInitialActionRawIndex', newInitialActionRawIndex)
              //   commit('setPerformingActions', true)
              //   // newActionHistory.forEach((recordedAction, index) => {
              //   //   const deserialize = recordedAction.deserialize ?? JSON.parse
              //   //   const action = deserialize(recordedAction.actionSnapshot)
              //   //   if (debug) console.log('Re-performing action', action)
              //   //   dispatch(
              //   //     action.type,
              //   //     {
              //   //       ...action.payload,
              //   //       isUndoRedoAction: true,
              //   //     },
              //   //     { root: true },
              //   //   )
              //   //   if (index === newInitialActionRawIndex - 1)
              //   //     dispatch(updateInitialStateActionName, null, { root: true })
              //   // })
              //   if (debug) console.log({ newActionHistory })
              //   for (const [index, recordedAction] of newActionHistory.entries()) {
              //     const deserialize = recordedAction.deserialize ?? JSON.parse
              //     const action = deserialize(recordedAction.actionSnapshot)
              //     if (debug)
              //       console.log('Re-performing action', {
              //         action,
              //         ...(stateGetter && {
              //           statePreActionSnapshot: JSON.parse(JSON.stringify(rootGetters[stateGetter])),
              //         }),
              //       })
              //     await dispatch(
              //       action.type,
              //       {
              //         ...action.payload,
              //         isUndoRedoAction: true,
              //       },
              //       { root: true },
              //     )
              //     if (index === newInitialActionRawIndex - 1) {
              //       if (debug) console.log('Dispatching updateInitialStateActionName')
              //       await dispatch(updateInitialStateActionName, null, { root: true })
              //     }
              //   }
              //   commit('setPerformingActions', false)
              //   newActionHistory = newActionHistory.slice(newInitialActionRawIndex)
              //   newCurrentActionHistoryIndex = newActionHistory.length - 1
              // } else if (debug)
              //   console.log(
              //     `There are still ${
              //       historySize - visibleTrackedActions.length
              //     } positions free in the visible actions history stack`,
              //   )
              if (visibleTrackedActions.length > historySize) {
                if (debug) console.log('Too many visible actions. Shifting action history stack')
                const newInitialActionRawIndex =
                  newActionHistory.findIndex((recordedAction) => !recordedAction.hide) + 1
                if (debug) console.log('newInitialActionRawIndex', newInitialActionRawIndex)
                // Jump to current state version
                if (stateGetter) {
                  const firstAction = newActionHistory[0]
                  const deserialize = firstAction.deserialize ?? JSON.parse
                  const action = deserialize(firstAction.actionSnapshot)
                  const currentStateSnapshot = JSON.stringify(rootGetters[stateGetter])
                  if (debug) console.log({ statePreActionShift: JSON.parse(currentStateSnapshot) })
                  commit('setPerformingActions', true)
                  await dispatch(resetStateActionName, null, { root: true })
                  if (debug)
                    console.log('Re-performing action', {
                      action: {
                        type: action.type,
                        payload: {
                          ...action.payload,
                          isUndoRedoAction: true,
                          isGeneratingNewBaseState: true,
                        },
                      },
                      ...(stateGetter && {
                        statePreActionSnapshot: JSON.parse(JSON.stringify(rootGetters[stateGetter])),
                      }),
                    })
                  await dispatch(
                    action.type,
                    {
                      ...action.payload,
                      isUndoRedoAction: true,
                      isGeneratingNewBaseState: true,
                    },
                    { root: true },
                  )
                  if (debug)
                    console.log(`Dispatching ${updateInitialStateActionName}`, {
                      statePostActionSnapshot: JSON.parse(JSON.stringify(rootGetters[stateGetter])),
                    })
                  await dispatch(updateInitialStateActionName, null, { root: true })
                  await dispatch(resetStateActionName, { newStateSnapshot: currentStateSnapshot }, { root: true })
                  commit('setPerformingActions', false)
                } else {
                  // Redo all actions version
                  await dispatch(resetStateActionName, null, { root: true })

                  commit('setPerformingActions', true)
                  if (debug) console.log({ newActionHistory })

                  for (const [index, recordedAction] of newActionHistory.entries()) {
                    const deserialize = recordedAction.deserialize ?? JSON.parse
                    const action = deserialize(recordedAction.actionSnapshot)
                    if (debug)
                      console.log('Re-performing action', {
                        action,
                        ...(stateGetter && {
                          statePreActionSnapshot: JSON.parse(JSON.stringify(rootGetters[stateGetter])),
                        }),
                      })
                    await dispatch(
                      action.type,
                      {
                        ...action.payload,
                        isUndoRedoAction: true,
                      },
                      { root: true },
                    )
                    if (index === newInitialActionRawIndex - 1) {
                      if (debug) console.log('Dispatching updateInitialStateActionName')
                      await dispatch(updateInitialStateActionName, null, { root: true })
                    }
                  }
                  commit('setPerformingActions', false)
                }
                newActionHistory = newActionHistory.slice(newInitialActionRawIndex)
                newCurrentActionHistoryIndex = newActionHistory.length - 1
              } else if (debug)
                console.log(
                  `There are still ${
                    historySize - visibleTrackedActions.length
                  } positions free in the visible actions history stack`,
                )
            }
          }
          if (debug) console.log({ newActionHistory, newCurrentActionHistoryIndex })
          commit('setActionHistory', newActionHistory)
          commit('setCurrentActionHistoryIndex', newCurrentActionHistoryIndex)
        },
      },
    }

    vuexStore.registerModule([parentModuleName, 'actions'], { ...actionsModuleConfig })

    // Tracking actions
    vuexStore.subscribeAction({
      after: (action, state) => {
        const trackedAction = actionsToTrack.find((trackedAction) => trackedAction.type === action.type)
        if (
          !state[parentModuleName].actions.performingActions &&
          trackedAction &&
          (action.payload ? action.payload.persist === undefined || action.payload.persist : true)
        ) {
          if (debug)
            console.log('Registering action ', {
              action,
              state,
              ...trackedAction,
              hide: !!trackedAction.hide,
            })
          vuexStore.dispatch(`${parentModuleName}/actions/logAction`, {
            action,
            ...trackedAction,
            hide: !!trackedAction.hide,
          })
        }
      },
    })
  }
}
