import { v4 as uuidv4 } from 'uuid'
import { CONTENT_TYPE, ELEMENT_STYLE, ELEMENT_TYPE, MEDIA_TYPES } from 'adificial-common/dist/enums/adstudio'
import { NONE_TRANSITION } from 'adificial-common/dist/defaults'

export default {
  setBaseURL(state, url) {
    state.baseURL = url
  },
  setZoom(state, data) {
    state.zoom = data
  },
  setSceneLayoutAttribute(state, { name, value }) {
    state.sceneLayout[name] = value
  },
  setSceneLayoutSize(state, { width, height }) {
    state.sceneLayout.width = width
    state.sceneLayout.height = height
  },
  setContentType(state, newContentType) {
    state.contentType = newContentType
  },
  setChapter(state, chapter) {
    state.chapter = chapter
  },
  setChapterAttribute(state, updateAttributeObj) {
    state.chapter[updateAttributeObj.name] = updateAttributeObj.value
  },
  setChapterSize(state, { width, height }) {
    state.chapter.width = width
    state.chapter.height = height
  },
  setChapterAudio(state, audio) {
    state.needsToRegenerateAudio = true
    state.chapter.audio = audio
  },
  setChapterAudioVolume(state, volume) {
    if (state.chapter?.audio) {
      state.chapter.audio.volume = volume
    }
  },
  setElementAttribute(_, updateAttributeObject) {
    updateAttributeObject.element[updateAttributeObject.attribute] = updateAttributeObject.attributeValue
  },
  setElementSizeAndPosition(_, { element, x, y, height, width }) {
    element.width = width
    element.height = height
    element.x = x || element.x
    element.y = y || element.y
  },
  setColorHistoryItem(state, color) {
    if (state.chapter.colorHistory.includes(color)) {
      state.chapter.colorHistory.splice(state.chapter.colorHistory.indexOf(color), 1)
      state.chapter.colorHistory.unshift(color)
      return
    }
    if (state.chapter.colorHistory.length === 6) {
      state.chapter.colorHistory.pop()
    }
    state.chapter.colorHistory.unshift(color)
  },
  setTextElementStyles(_, eupdateStyleObj) {
    if (eupdateStyleObj.element.type !== ELEMENT_TYPE.TEXT) {
      return
    }
    eupdateStyleObj.element.textStyles[eupdateStyleObj.style] = eupdateStyleObj.styleValue
  },
  addElementToScene(_, { scene, newElement }) {
    scene.elements.push(newElement)
  },
  newScene(state, newScene) {
    state.needsToRegenerateAudio = true
    state.chapter.scenes.push(newScene)
  },
  addSceneAtIndex(state, { newScene, index }) {
    state.needsToRegenerateAudio = true
    state.chapter.scenes.splice(index, 0, newScene)
  },
  changeSceneContentAtIndex(state, { indexToUpdate, newContent }) {
    if (!state.chapter.scenes?.[indexToUpdate]) {
      return
    }
    state.chapter.scenes[indexToUpdate] = newContent
  },
  updateFirstTimer(state, { property, value }) {
    if (!Object.hasOwn(state.firstTimer, property)) {
      return
    }
    state.firstTimer[property] = value
  },
  addSceneTransitionAtIndex(state, { newTransition, index }) {
    state.needsToRegenerateAudio = true
    state.chapter.sceneTransitions.splice(index, 0, newTransition)
  },
  newTransition(state) {
    state.needsToRegenerateAudio = true
    state.chapter.sceneTransitions.push(NONE_TRANSITION)
  },
  setActiveScene(state, targetIndex) {
    const scenes = state.contentType === CONTENT_TYPE.SCENE_LAYOUT ? [state.sceneLayout] : state.chapter.scenes
    targetIndex = Math.min(scenes.length - 1, targetIndex)
    targetIndex = Math.max(0, targetIndex)
    scenes.forEach((scene, index) => {
      scene.active = index === targetIndex
    })
  },
  duplicateScene(state, sceneIndexToClone) {
    state.needsToRegenerateAudio = true
    const sceneToClone = state.chapter.scenes[sceneIndexToClone]
    const clonedElements = []
    if (sceneToClone) {
      const newScene = JSON.parse(JSON.stringify(sceneToClone))

      sceneToClone.elements.forEach((element) => {
        const clone = JSON.parse(JSON.stringify(element))
        clone.id = uuidv4()
        clonedElements.push(clone)
      })

      sceneToClone.active = false
      newScene.elements = clonedElements
      newScene.id = uuidv4()
      newScene.active = true
      state.chapter.scenes.push(newScene)
    }
  },
  setSceneDuration(state, { value, index }) {
    state.needsToRegenerateAudio = true
    state.chapter.scenes[index].duration = value
  },
  removeScene(state, index) {
    state.needsToRegenerateAudio = true
    state.chapter.scenes.splice(index, 1)
  },
  removeTransition(state, index) {
    state.needsToRegenerateAudio = true
    state.chapter.sceneTransitions.splice(index, 1)
  },
  setScenes(state, scenes) {
    state.needsToRegenerateAudio = true
    state.chapter.scenes = scenes
  },
  activateElement(state, element) {
    element.active = true
  },
  deactivateElement(_, element) {
    element.active = false
  },
  setElementPosition(_, { element, x, y }) {
    element.x = x
    element.y = y
  },
  setSubMenu(state, subMenu) {
    state.subMenu = subMenu
  },
  setIsTextEditing(state, data) {
    state.isTextEditing = data
  },
  setIsEditingOptions(state, data) {
    state.isTextEditing = data
  },
  setVoiceoverConfig(state, obj) {
    state.needsToRegenerateAudio = true
    state.chapter.voiceOverConfig = obj
  },
  deselectAllText() {
    if (window.getSelection) {
      window.getSelection().removeAllRanges()
    } else if (document.selection) {
      document.selection.empty()
    }
  },
  handleVerticalResize(_, element) {
    const targetEl = document.querySelector('#AD_text_' + element.id)
    targetEl.style.height = 'auto'
    const heightContent = parseInt(targetEl?.scrollHeight)
    if (element.height !== heightContent) {
      element.height = heightContent
    }
  },
  handleHorizontalResize(_, element) {
    const targetEl = document.querySelector('#AD_text_' + element.id)
    targetEl.style.width = 'auto'
    const widthContent = parseInt(targetEl?.scrollWidth)
    if (element.width !== widthContent) {
      element.width = widthContent
    }
  },
  setSceneElements(state, { scene, elements }) {
    const bg = scene.elements[0]
    elements.unshift(bg)
    scene.elements = elements
  },
  moveElement(state, { action, element, scene }) {
    const fromIndex = scene.elements.indexOf(element)
    let toIndex = 0
    switch (action) {
      case 'down':
        if (fromIndex === 1) {
          toIndex = fromIndex
        } else {
          toIndex = fromIndex - 1
        }
        break
      case 'up':
        toIndex = fromIndex + 1
        break
      case 'front':
        toIndex = scene.elements.length - 1
        break
      case 'back':
        toIndex = 1
        break
    }
    scene.elements.splice(fromIndex, 1)
    scene.elements.splice(toIndex, 0, element)
  },
  duplicateActiveSceneElements(_, scene) {
    if (scene.active) {
      const clones = scene.elements
        .map((originalElement) => {
          if (originalElement.active && originalElement.type !== ELEMENT_TYPE.BACKGROUND) {
            const clone = JSON.parse(JSON.stringify(originalElement))
            clone.id = uuidv4()
            clone.x += 10
            clone.y += 10
            clone.active = true
            originalElement.active = false
            return clone
          }
          return undefined
        })
        .filter(Boolean)
      scene.elements = [...scene.elements, ...clones]
    }
  },
  setTab(state, tab) {
    state.tab = tab
  },
  setMedia(state, { type, value }) {
    state.media[type] = value
  },
  setIsEditing(state, value) {
    state.isEditing = value
  },
  setElementSize(_, { element, height, width }) {
    element.width = width
    element.height = height
  },
  setConnections(state, connectionsArray) {
    state.connections = connectionsArray
  },
  setUserProfile(state, userProfile) {
    state.userProfile = userProfile
  },
  setUserCompanyInfo(state, companyInfo) {
    state.userProfile.company = companyInfo
  },
  removeElementsFromList({ media }, { elementsList, elementIds }) {
    elementIds.forEach((elementId) => {
      const index = elementsList.findIndex((el) => el.id === elementId)
      const mediaToDiscardIndex = media.mediaToUpload.findIndex((m) => m.elementToUpdate.id === elementId)
      if (mediaToDiscardIndex !== -1) {
        URL.revokeObjectURL(elementsList[index].src)
        media.mediaToUpload.splice(mediaToDiscardIndex, 1)
      }
      elementsList.splice(index, 1)
    })
  },
  setFontPage(state, fontPage) {
    state.fontPage = fontPage
  },
  setSelectedConnection(state, id) {
    state.selectedConnection = id
    state.chapter.connectionId = id
  },
  setAPIError(state, isError) {
    state.apiError = isError
  },
  setSceneTransitionsConfig(state, newSceneTransitionsConfig) {
    state.sceneTransitionsConfig = newSceneTransitionsConfig
  },
  setSceneTransitionAtIndex(state, { newSceneTransitionIndex, newSceneTransition }) {
    state.needsToRegenerateAudio = true
    state.chapter.sceneTransitions.splice(newSceneTransitionIndex, 1, newSceneTransition)
  },
  setShouldRefresh(state, should) {
    state.shouldRefresh = should
  },
  setShouldRedirect(state, should) {
    state.shouldRedirect = should
  },
  setIsSaving(state, is) {
    state.isSaving = is
  },
  setSceneLayout(state, newSceneLayout) {
    state.sceneLayout = newSceneLayout
  },
  setShouldAutosave(state, auto) {
    state.shouldAutosave = auto
  },
  setBrands(state, brands) {
    state.brands = brands
  },
  setCurrentBrand(state, brand) {
    state.currentBrand = brand
  },
  setShowProfileMenu(state, showProfileMenu) {
    state.showProfileMenu = showProfileMenu
  },
  setIsResizing(state, resizing) {
    state.isResizing = resizing
  },
  setSavedSceneId(state, { index, id }) {
    state.chapter.scenes[index].sceneId = id
  },
  setVideoLoaded(state, index) {
    state.media.stockVideos[index].loaded = true
  },
  setImageLoaded(state, { index, array }) {
    const imageToMark = state.media[array][index]
    if (imageToMark) imageToMark.loaded = true
  },
  clearLoaded(state) {
    ;[MEDIA_TYPES.STOCK_IMAGES, MEDIA_TYPES.STOCK_VIDEOS, MEDIA_TYPES.PEXELS, MEDIA_TYPES.PEXELS_VIDEOS].forEach(
      (array) => {
        state.media[array] = []
      },
    )
    state.media.currentPage = 1
    state.media.currentStockPage = 0
    state.media.query = ''
  },
  setAccountAccess(state, access) {
    state.accountAccess = access
  },
  setCompanyId(state, id) {
    state.userProfile.companyId = id
  },
  setElementsCopied(state, elements) {
    state.elementsCopied = elements || []
  },
  setLastBG(state, { color, imageSrc, videoSrc }) {
    if (color !== undefined) state.lastBGColor = color
    if (imageSrc !== undefined) state.lastBGImage = imageSrc
    if (videoSrc !== undefined) state.lastBGVideo = videoSrc
  },
  setInitialContent(state, initialContent) {
    state.initialContent = JSON.stringify(initialContent)
  },
  resetContent(state, { newContentSnapshot } = {}) {
    const deserializedNewContent = JSON.parse(newContentSnapshot || state.initialContent)
    switch (state.contentType) {
      case CONTENT_TYPE.CHAPTER:
        state.chapter = deserializedNewContent
        break
      case CONTENT_TYPE.SCENE_LAYOUT:
        state.sceneLayout = deserializedNewContent
        break
      default:
        break
    }
  },
  setDatapointDefaultValues(state, datapointDefaultValues) {
    state.chapter.defaultValues = datapointDefaultValues
  },
  setAvailableFeaturesForUser(state, features) {
    state.userProfile.permissions = features || []
  },
  setPreviewDefaults(state, previewDefaults) {
    state.usePreviewDefaults = previewDefaults
  },
  setSelectedElementDynamicImage(state, dynamicImage) {
    const activeElements = state.chapter.scenes
      .filter((scene) => scene.active)[0]
      .elements.filter((element) => element.active)

    if (activeElements.length === 0) return

    dynamicImage.type ??= 'userProvided'
    dynamicImage.datapoint ??= ''

    activeElements[0].dynamicImage = dynamicImage
  },
  setActiveBackgroundDynamicImage(state, dynamicImage) {
    const activeBackground = state.chapter.scenes.find((scene) => scene.active)
      ? state.chapter.scenes.find((scene) => scene.active).elements.find((el) => el.type === ELEMENT_TYPE.BACKGROUND)
      : undefined

    if (!activeBackground) return

    dynamicImage.type ??= 'userProvided'
    dynamicImage.datapoint ??= ''

    activeBackground.dynamicImage = dynamicImage
  },
  setSceneVoiceOver(state, { voiceOver, index }) {
    state.needsToRegenerateAudio = true
    state.chapter.scenes[index].voiceOver = voiceOver
    if (
      voiceOver?.duration > state.chapter.scenes[index].duration &&
      !state.chapter?.voiceOverConfig?.isChapterVoiceover
    ) {
      const initialDelay = index === 0 ? 2 : 0
      state.chapter.scenes[index].duration = Math.ceil(voiceOver.duration) + initialDelay
    }
  },
  setVoiceOverState(state, newState) {
    state.voiceOverState = newState
  },
  setChapterAudioState(state, newState) {
    state.chapterAudioState = newState
  },
  removeDuplicateBackgrounds(state) {
    const scenes = state.chapter.scenes

    scenes.forEach((scene) => {
      const elements = scene.elements
      const backgrounds = elements.filter((el) => el.type === ELEMENT_TYPE.BACKGROUND)
      if (backgrounds.length <= 1) {
        return
      }

      backgrounds.forEach((bg, index) => {
        if (index === 0) return

        const elementIndex = elements.indexOf(bg)
        elements.splice(elementIndex, 1)
      })
    })
  },
  setElementAnimationAtIndex(state, { element, newAnimation, index, replace }) {
    if (newAnimation !== undefined) element[ELEMENT_STYLE.ANIMATIONS_TIMELINE].splice(index, +replace, newAnimation)
    else element[ELEMENT_STYLE.ANIMATIONS_TIMELINE].splice(index, +replace)
  },
  addDynamicImage(state, image) {
    state.dynamicImages.push(image)
  },
  setDynamicImageValue(state, { imageIndex, value }) {
    state.dynamicImages[imageIndex].value = value
  },
  setDynamicImage(state, { image, index }) {
    state.dynamicImages[index] = {
      ...state.dynamicImages[index],
      ...image,
    }
  },
  deleteDynamicImage(state, imageIndex) {
    state.dynamicImages.splice(imageIndex, 1)
  },
  setEditingDynamicImageIndex(state, index) {
    state.editingDynamicImageIndex = index
  },
  setMutateSceneContent(state, shouldMutate) {
    state.mutateSceneContent = shouldMutate
  },
  setAudioRequestID(state, id) {
    state.audioRequestID = id
  },
  setAudioRequest(state, req) {
    state.audioRequest = req
  },
  setPlaybackPending(state, status) {
    state.playbackPending = status
  },
  setNeedsToRegenerateAudio(state, needs) {
    state.needsToRegenerateAudio = needs
  },
  setShowUnsavedChangesModal(state, showUnsavedChangesModal) {
    state.showUnsavedChangesModal = showUnsavedChangesModal
  },
  setUnsavedVoiceOverChanges(state, unsavedVoiceOverChanges) {
    state.unsavedVoiceOverChanges = unsavedVoiceOverChanges
  },
}
