import { default as indexc6eymE8NlCMeta } from "/vercel/path0/pages/[[contentId]]/index.vue?macro=true";
import { default as indexCsv39gyrL4Meta } from "/vercel/path0/pages/landingPages/edit/[[landingId]]/index.vue?macro=true";
import { default as indexoOBDY93e9EMeta } from "/vercel/path0/pages/login/index.vue?macro=true";
export default [
  {
    name: indexc6eymE8NlCMeta?.name ?? "contentId",
    path: indexc6eymE8NlCMeta?.path ?? "/:contentId?",
    meta: indexc6eymE8NlCMeta || {},
    alias: indexc6eymE8NlCMeta?.alias || [],
    redirect: indexc6eymE8NlCMeta?.redirect,
    component: () => import("/vercel/path0/pages/[[contentId]]/index.vue").then(m => m.default || m)
  },
  {
    name: indexCsv39gyrL4Meta?.name ?? "landingPages-edit-landingId",
    path: indexCsv39gyrL4Meta?.path ?? "/landingPages/edit/:landingId?",
    meta: indexCsv39gyrL4Meta || {},
    alias: indexCsv39gyrL4Meta?.alias || [],
    redirect: indexCsv39gyrL4Meta?.redirect,
    component: () => import("/vercel/path0/pages/landingPages/edit/[[landingId]]/index.vue").then(m => m.default || m)
  },
  {
    name: indexoOBDY93e9EMeta?.name ?? "login",
    path: indexoOBDY93e9EMeta?.path ?? "/login",
    meta: indexoOBDY93e9EMeta || {},
    alias: indexoOBDY93e9EMeta?.alias || [],
    redirect: indexoOBDY93e9EMeta?.redirect,
    component: () => import("/vercel/path0/pages/login/index.vue").then(m => m.default || m)
  }
]