import { v4 as uuidv4 } from 'uuid'
import { useToast, POSITION } from 'vue-toastification'
import { backOff } from 'exponential-backoff'

import {
  MEDIA_TYPES,
  CONTENT_TYPE,
  ELEMENT_TYPE,
  ELEMENT_BACKGROUND_TYPE,
  FILE_TYPE,
} from 'adificial-common/dist/enums/adstudio'
import { getVideoDimensions, getVideoInfo } from 'adificial-common/dist/util/mediaUtils'
import { defaultAudio } from 'adificial-common/dist/defaults'

import {
  createChapter,
  createSceneLayout,
  fetchBrands,
  fetchChapter,
  fetchConnections,
  fetchMedia,
  fetchPexelsImages,
  fetchPexelsVideos,
  fetchSceneLayout,
  fetchSetSceneToFavorites,
  fetchRemoveSceneFromFavorites,
  fetchFavoritesScenes,
  fetchProfile,
  saveMedia,
  updateChapter,
  updateSceneLayout,
  deleteMediaFile,
  getTextToSpeechVoices,
  fetchRoyaltyFreeAudio,
  fetchGenerateTextToSpeech,
  fetchCompanyLabels,
  IMAGES_PER_PAGE,
  fetchAudioRequest,
} from './api'
import userActions from './userActions'

const toast = useToast()

export default function useEditorActions({ auth }) {
  const checkAudio = ({ state, commit, getters }, id) => {
    return fetchAudioRequest({ state, commit, getters }, id).then((updatedAudioRequest) => {
      commit('setAudioRequest', updatedAudioRequest)
      if (updatedAudioRequest.status === 'PROCESSING') {
        throw new Error('Still processing')
      } else {
        return updatedAudioRequest
      }
    })
  }

  const exports = {
    ...userActions,
    pasteElements({ commit, getters, dispatch }) {
      const copiedElements = getters.elementsCopied?.map((element) => {
        const clone = JSON.parse(JSON.stringify(element))
        return { ...clone, id: uuidv4(), active: false }
      })

      switch (getters.contentType) {
        case CONTENT_TYPE.CHAPTER:
          dispatch('addElementsToSceneAtIndex', { sceneIndex: getters.activeSceneIndex, newElements: copiedElements })
          break
        case CONTENT_TYPE.SCENE_LAYOUT:
          dispatch('addElementsToSceneLayout', { newElements: copiedElements })
          break
        default:
          break
      }
    },
    deactivateAll({ dispatch }) {
      // Omitting the element deactivates them all
      dispatch('deactivateAllElementsBut')
    },
    deactivateAllElementsBut({ commit, getters, state }, elementToKeepActive) {
      switch (getters.contentType) {
        case CONTENT_TYPE.CHAPTER:
          getters.activeScene?.elements.forEach((el) =>
            elementToKeepActive && elementToKeepActive.id === el.id ? null : commit('deactivateElement', el),
          )
          break
        case CONTENT_TYPE.SCENE_LAYOUT:
          state.sceneLayout.elements.forEach((el) =>
            elementToKeepActive && elementToKeepActive.id === el.id ? null : commit('deactivateElement', el),
          )
          break
        default:
          break
      }
    },
    fetchGetChapter(store, body) {
      return fetchChapter(store, body)
    },
    fetchGetTextToSpeechVoices(store, body) {
      return getTextToSpeechVoices(store, body)
    },
    async generateTextToSpeech(store, { payload, sceneIndex, audioType }) {
      await auth.refreshTokenIfRequired()
      return fetchGenerateTextToSpeech(store, { payload, sceneIndex, audioType })
    },
    async fetchGetMediaList(store, { payload, $infiniteState }) {
      await auth.refreshTokenIfRequired()
      return fetchMedia(store, payload, $infiniteState)
    },
    async fetchGetRoyaltyFreeAudio(store, { payload, $infiniteState }) {
      await auth.refreshTokenIfRequired()
      return fetchRoyaltyFreeAudio(store, payload, $infiniteState)
    },
    async fetchGetMediaFromPexels(store, $infiniteState) {
      await fetchPexelsImages(store, $infiniteState)
    },
    async fetchGetVideosFromPexels(store, $infiniteState) {
      await fetchPexelsVideos(store, $infiniteState)
    },
    async fetchAvailableConnections(store, companyID) {
      await auth.refreshTokenIfRequired()
      return fetchConnections(store, companyID)
    },
    async fetchGetSceneLayout(store, payload) {
      await auth.refreshTokenIfRequired()
      fetchSceneLayout(store, payload)
    },
    async listBrands(store, companyID) {
      await auth.refreshTokenIfRequired()
      await fetchBrands(store, companyID)
    },
    async fetchSaveMedia(store, payload) {
      await auth.refreshTokenIfRequired()
      return await saveMedia(store, payload)
    },
    async fetchDeleteMedia(store, payload) {
      await auth.refreshTokenIfRequired()
      return await deleteMediaFile(store, payload)
    },
    async getUserProfile(store) {
      await fetchProfile(store)
    },
    setSceneVoiceOver({ commit, state }, { voiceOver, index }) {
      if (state.chapter.scenes[index]?.voiceOver?.src === voiceOver?.src) {
        commit('setVoiceOverState', {
          isLoading: false,
          errorMessage: '',
        })
      }

      commit('setSceneVoiceOver', { voiceOver, index })
    },
    async updateVideoDimensions({ commit, getters }, { element, src }) {
      const { height, width } = await getVideoInfo(src)

      if (!!width && !!height) {
        const maxWidth = getters.chapter.width
        const maxHeight = getters.chapter.height

        const dim = getVideoDimensions(width, height, maxWidth, maxHeight)

        commit('setElementSize', {
          element,
          width: dim.width,
          height: dim.height,
        })
      }
    },
    async uploadPendingMedia({ getters, dispatch, commit }) {
      const mediaToUpload = getters.media[MEDIA_TYPES.MEDIA_TO_UPLOAD]
      const mediaFailedToUpload = []

      for (const file of mediaToUpload) {
        if (
          file.elementToUpdate.type !== ELEMENT_TYPE.BACKGROUND ||
          (file.elementToUpdate.background.type === ELEMENT_BACKGROUND_TYPE.IMAGE &&
            file.mediaType === FILE_TYPE.IMAGE) ||
          (file.elementToUpdate.background.type === ELEMENT_BACKGROUND_TYPE.VIDEO && file.mediaType === FILE_TYPE.VIDEO)
        ) {
          try {
            const mediaData = await dispatch('fetchSaveMedia', { mediaFile: file })
            if (mediaData?.mediaUrl) {
              const mediaParsedObj = {
                mediaId: mediaData.mediaId,
                mediaUrl: mediaData.mediaUrl,
                mediaName: file.name,
              }

              const mediaTypesByFileType = {
                [FILE_TYPE.IMAGE]: MEDIA_TYPES.STOCK_IMAGES,
                [FILE_TYPE.VIDEO]: MEDIA_TYPES.STOCK_VIDEOS,
                [FILE_TYPE.AUDIO]: MEDIA_TYPES.STOCK_AUDIO,
              }
              commit('setMedia', {
                type: mediaTypesByFileType[file.mediaType],
                value: [mediaParsedObj, ...getters.media[mediaTypesByFileType[file.mediaType]]],
              })
              switch (file.mediaType) {
                case FILE_TYPE.IMAGE:
                case FILE_TYPE.VIDEO: {
                  const otherElementsToUpdate = getters.allElements.filter((element) => {
                    const isSameElement = element.id === file.elementToUpdate.id
                    const srcValue =
                      file.elementToUpdate.type === ELEMENT_TYPE.BACKGROUND
                        ? file.elementToUpdate.background?.value
                        : file.elementToUpdate.src
                    const sameBackgroundSrc =
                      element.type === ELEMENT_TYPE.BACKGROUND && srcValue === element.background?.value
                    const sameMediaSrc =
                      [ELEMENT_TYPE.IMAGE, ELEMENT_TYPE.VIDEO].includes(element.type) && srcValue === element.src
                    const sameSource = sameBackgroundSrc || sameMediaSrc
                    const result = !isSameElement && sameSource
                    return result
                  })
                  // We re-load the element to update using it's ID since it's possible the element object has been replaced during the shift process of the undo/redo queue.
                  const elementsToUpdate = [getters.getElementById(file.elementToUpdate.id), ...otherElementsToUpdate]
                  for (const elementToUpdate of elementsToUpdate) {
                    const attribute = elementToUpdate.type === ELEMENT_TYPE.BACKGROUND ? 'background' : 'src'
                    const attributeValue =
                      elementToUpdate.type === ELEMENT_TYPE.BACKGROUND
                        ? { ...elementToUpdate.background, value: mediaData.mediaUrl }
                        : mediaData.mediaUrl
                    const urlToRevoke =
                      elementToUpdate.type === ELEMENT_TYPE.BACKGROUND
                        ? elementToUpdate.background.value
                        : elementToUpdate.src

                    URL.revokeObjectURL(urlToRevoke)
                    commit('setElementAttribute', {
                      element: elementToUpdate,
                      attribute,
                      attributeValue,
                    })
                    dispatch('updateVideoDimensions', { element: elementToUpdate, src: attributeValue })
                    if (elementToUpdate.type === ELEMENT_TYPE.BACKGROUND) {
                      commit('setLastBG', { videoSrc: mediaData.mediaUrl })
                    }
                  }
                  break
                }
                case FILE_TYPE.AUDIO: {
                  const isVoiceOver = getters.sceneVoiceOver?.id === file.elementToUpdate.id
                  const chapterAudio = defaultAudio()

                  if (!isVoiceOver) {
                    chapterAudio.fadeOut = getters.chapter.audio?.fadeOut || false
                    chapterAudio.audioAttenuation = getters.chapter.audio?.audioAttenuation || 0.8
                  }

                  function getDuration(src) {
                    return new Promise(function (resolve) {
                      const audio = new Audio()
                      audio.addEventListener('loadedmetadata', function () {
                        resolve(audio.duration)
                      })
                      audio.src = src
                    })
                  }

                  chapterAudio.id = file.elementToUpdate.id
                  chapterAudio.duration = await getDuration(mediaData.mediaUrl)
                  chapterAudio.name = file.name
                  chapterAudio.src = mediaData.mediaUrl

                  isVoiceOver
                    ? dispatch('setSceneVoiceOver', {
                        voiceOver: chapterAudio,
                        index: getters.activeSceneIndex,
                        persist: false,
                      })
                    : dispatch('setChapterAudio', { audio: chapterAudio, persist: false })
                  break
                }
              }
            }
          } catch (error) {
            toast.error(`Failed to upload media file: ${file.name}`)
            mediaFailedToUpload.push(file)
            throw error
          }
        }
      }
      commit('setMedia', {
        type: MEDIA_TYPES.MEDIA_TO_UPLOAD,
        value: mediaFailedToUpload,
      })
    },

    async saveContent(
      { commit, state, getters, rootGetters, dispatch },
      { generateThumbnail = false, silent = false },
    ) {
      await auth.refreshTokenIfRequired()
      commit('removeDuplicateBackgrounds')
      commit('setIsSaving', true)
      const contentType = getters.contentType
      const content = contentType === CONTENT_TYPE.CHAPTER ? getters.chapter : getters.sceneLayout
      const isNew = getters.isNew
      const companyId = rootGetters['editor/userProfile'].companyId

      // First, upload the pending assets. However, don't throw if some of them fail to be uploaded to prevent user from being blocked from saving their content

      await dispatch('uploadPendingMedia') // NO SEARCH AND REPLACE

      let saveMethod
      switch (contentType) {
        case CONTENT_TYPE.CHAPTER:
          saveMethod = isNew ? createChapter : updateChapter
          break
        case CONTENT_TYPE.SCENE_LAYOUT:
          saveMethod = isNew ? createSceneLayout : updateSceneLayout
          break
        default:
          break
      }

      if (saveMethod) {
        const {
          chapterId,
          id,
          message: apiMessage,
          hasError,
          status,
        } = await saveMethod(
          { commit, state, getters },
          {
            generateThumbnail,
            ...content,
            ...(companyId && isNew ? { companyId } : null),
          },
        )

        commit('setIsSaving', false)
        const messageToShow = apiMessage

        if (!hasError) {
          if (chapterId) {
            const newChapterIdAttributeObj = {
              name: 'chapterId',
              value: chapterId,
            }
            const newCompanyIdAttributeObj = {
              name: 'companyId',
              value: companyId,
            }
            commit('setChapterAttribute', newChapterIdAttributeObj)
            commit('setChapterAttribute', newCompanyIdAttributeObj)
          } else if (id) {
            const newAttributeObj = {
              name: 'id',
              value: id,
            }
            commit('setSceneLayoutAttribute', newAttributeObj)
          }

          if (!silent) {
            toast.success(messageToShow)
          }

          setTimeout(() => {
            const accountAccess = getters.accountAccess
            if (chapterId) {
              commit(
                'setShouldRedirect',
                `${chapterId}${accountAccess.enabled ? `?companyId=${accountAccess.id}` : ''}`,
              )
            } else if (id) {
              commit(
                'setShouldRedirect',
                `${id}?contentType=${contentType}${accountAccess.enabled ? `&companyId=${accountAcces.id}` : ''}`,
              )
            }
          }, 1000)
        } else if (status === 401 || apiMessage.includes('Network Error')) {
          commit('setShouldRefresh', true)
          toast.error('Refreshing your session in order to save your changes. Please wait...', {
            onClose: () => window.location.reload(),
            timeout: 2000,
          })
        } else {
          toast.error(messageToShow)
        }
      }
    },
    async saveSceneToFavorite(store, payload) {
      await auth.refreshTokenIfRequired()
      const data = await fetchSetSceneToFavorites(store, payload)

      if (data) {
        store.commit('setSavedSceneId', { index: payload.sceneIndex, id: data?.id })
        store.dispatch('saveContent', { generateThumbnail: true })
      }
    },
    async removeSceneFromFavorite(store, { index, id }) {
      await auth.refreshTokenIfRequired()
      const data = await fetchRemoveSceneFromFavorites(store, id)

      if (data) {
        store.commit('setSavedSceneId', { index, id: null })
        store.dispatch('saveContent', { generateThumbnail: true })
      }
    },
    async getSceneLayouts(store, { companyId, isTemplate }) {
      await auth.refreshTokenIfRequired()
      const data = await fetchFavoritesScenes(store, { companyId, isTemplate })
      return data || []
    },
    enableAccountAccess({ getters, commit }, { id, name }) {
      commit('setAccountAccess', {
        enabled: true,
        id,
        name,
        originalId: getters.companyId,
      })
      commit('setCompanyId', id)
    },
    resetThirdPartyImages({ commit }) {
      commit('setMedia', {
        type: MEDIA_TYPES.PEXELS,
        value: [],
      })
    },
    resetThirdPartyVideos({ commit }) {
      commit('setMedia', {
        type: MEDIA_TYPES.PEXELS_VIDEOS,
        value: [],
      })
    },
    resetThirdPartyAudios({ commit }) {
      commit('setMedia', {
        type: MEDIA_TYPES.THIRD_PARTY_AUDIO,
        value: [],
      })
    },
    resetCompanyVaultMedia({ commit }) {
      commit('setMedia', {
        type: MEDIA_TYPES.CURRENT_STOCK_PAGE,
        value: 0,
      })
      commit('setMedia', {
        type: MEDIA_TYPES.STOCK_IMAGES,
        value: [],
      })
      commit('setMedia', {
        type: MEDIA_TYPES.STOCK_VIDEOS,
        value: [],
      })
      commit('setMedia', {
        type: MEDIA_TYPES.STOCK_AUDIO,
        value: [],
      })
    },
    recalculateCurrentMediaPages({ state, getters, commit }, fileType) {
      const mediaTypesByFileType = {
        [FILE_TYPE.IMAGE]: MEDIA_TYPES.STOCK_IMAGES,
        [FILE_TYPE.VIDEO]: MEDIA_TYPES.STOCK_VIDEOS,
        [FILE_TYPE.AUDIO]: MEDIA_TYPES.STOCK_AUDIO,
      }
      commit('setMedia', {
        type: MEDIA_TYPES.CURRENT_STOCK_PAGE,
        value: Math.ceil(getters.media[mediaTypesByFileType[fileType]].length / IMAGES_PER_PAGE),
      })
    },
    setPreviewDefaults({ commit }, { newPreviewDefaultsValue }) {
      commit('setPreviewDefaults', newPreviewDefaultsValue)
    },

    async fetchCompanyLabels({ state, commit, getters }) {
      await auth.refreshTokenIfRequired()
      const debug = false
      const companyId = getters.userProfile.companyId
      if (debug) console.log({ companyId })
      const { labels, message, hasError } = await fetchCompanyLabels({ state, getters }, { companyId })
      if (debug) console.log({ labels })

      if (message) {
        if (hasError) toast.error(message)
        else toast.success(message)
      }
      const company = getters.userProfile.company
      commit('setUserCompanyInfo', { ...company, labels })
    },
    async fetchAudioRequest({ state, commit, getters }, id) {
      await auth.refreshTokenIfRequired()
      const response = await fetchAudioRequest({ state, commit, getters }, id)
      commit('setAudioRequest', response)
    },
    async awaitForAudioGenerated({ state, commit, getters }, id) {
      await auth.refreshTokenIfRequired()
      toast.info('Processing chapter audio...', {
        timeout: false,
      })
      try {
        const audioRequest = await backOff(() => checkAudio({ state, commit, getters }, id), {
          delayFirstAttempt: true,
          startingDelay: 1000,
        })
        if (audioRequest.status === 'SUCCESS') {
          commit('setNeedsToRegenerateAudio', false)
          toast.clear()
          return audioRequest.url
        } else if (audioRequest.status === 'ERROR') {
          toast.clear()
          throw new Error(audioRequest.errorMessage)
        }
      } catch (e) {
        toast.error('There was an unexpected error when generating audio', {
          timeout: 2000,
        })
      }
    },
    resolvePlayback({ state, commit, dispatch }) {
      const status = state.playbackPending
      const validStates = ['playActiveScene', 'playAllScenes', 'playAllFromActiveScene']
      if (validStates.includes(status)) {
        dispatch(`adstudio/${status}`, null, { root: true })
        commit('setPlaybackPending', 'none')
      }
    },
  }
  return exports
}
